import { ProviderInterface } from './index'
import { CreateMapOptions } from '../../MapsWrapper/types'
import '../../../extends/leaflet'
import { control, Map, TileLayer, YandexTileLayer } from 'leaflet'
import { LAYER_TYPE_SATELLITE, LAYER_TYPE_STREET } from '../constants'

type YANDEX_LAYERS = typeof LAYER_TYPE_STREET | typeof LAYER_TYPE_SATELLITE

export default class YandexProvider implements ProviderInterface {
  public async create(options: CreateMapOptions): Promise<Map> {
    const map = new Map(options.id, options)
    const layers = this.layers()
    map.addLayer(layers[options.currentLayerType ? (options.currentLayerType as YANDEX_LAYERS) : LAYER_TYPE_STREET])
    control.layers(layers).addTo(map)
    return map
  }

  private layers(): Record<YANDEX_LAYERS, TileLayer> {
    return {
      [LAYER_TYPE_STREET]: this.tileLayer(LAYER_TYPE_STREET),
      [LAYER_TYPE_SATELLITE]: this.tileLayer(LAYER_TYPE_SATELLITE),
    }
  }

  private tileLayer(t: YANDEX_LAYERS): TileLayer {
    switch (t) {
      case LAYER_TYPE_SATELLITE:
        return new YandexTileLayer('https://core-sat.maps.yandex.net/tiles?l=sat&x={x}&y={y}&z={z}')
      case LAYER_TYPE_STREET:
        return new YandexTileLayer('https://core-renderer-tiles.maps.yandex.net/tiles?l=map&x={x}&y={y}&z={z}')
    }
  }
}
