import { buildWebStorage } from 'axios-cache-interceptor'
import { createAxiosInstance } from './utils/axiosFactory'
import { useCorrelationID } from './interceptors/useCorrelationID'
import { useAuthorization } from './interceptors/useAuthorization'

const { REACT_APP_PROXY_URL = 'http://localhost:4000', REACT_APP_IS_VPN_ACTIVATED, REACT_APP_CACHE_TTL } = process.env

export const proxyClient = createAxiosInstance({
  baseURL: REACT_APP_PROXY_URL,
  headers: {
    get: {
      Accept: 'application/json, text/plain, */*',
    },
    post: {
      'Content-Type': 'multipart/form-data',
    },
  },
  ...(REACT_APP_CACHE_TTL && {
    cache: {
      interpretHeader: false,
      ttl: Number(REACT_APP_CACHE_TTL),
      storage: buildWebStorage(localStorage, 'proxy-cache:'),
    },
  }),
  interceptors: [
    useCorrelationID,
    useAuthorization,
    {
      request: {
        onFulfilled: (config) => {
          if (REACT_APP_IS_VPN_ACTIVATED) {
            config.url = config.url?.replace(/https?:\/\//, '')
          }

          return config
        },
        options: { synchronous: true },
      },
    },
  ],
})
