import { CircularProgress } from '@mui/material'
import { Component, ReactNode } from 'react'

export default class Loading extends Component {
  render(): ReactNode {
    return (
      <div key="loading" className="m-24 min-w-96 text-center">
        <p className="text-gray-300 text-2xl">Loading...</p>
        <CircularProgress color="inherit"></CircularProgress>
      </div>
    )
  }
}
