import { IconButton } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined'
import React from 'react'
import { SearchType } from './WikimapiaSearchComponent'

type WikimapiaSearchControlsProps = {
  loading: boolean
  searchType: (typeof SearchType)[keyof typeof SearchType]
  resultsQty: number
  filteredResultsQty: number
  query: string
  setQuery: React.Dispatch<React.SetStateAction<string>>
  searchOnWikimapia: () => void
  searchFeaturesByBbox: () => void
}

export function WikimapiaSearchControls({
  searchType,
  resultsQty,
  filteredResultsQty,
  query,
  setQuery,
  loading,
  searchOnWikimapia,
  searchFeaturesByBbox,
}: WikimapiaSearchControlsProps) {
  return (
    <>
      <div className="flex flex-col items-start justify-center">
        <span className="text-sm">{searchType === SearchType.SEARCH ? 'Wikimapia' : 'Features'}</span>
        <span className="text-xs">{`Results: ${filteredResultsQty}/${resultsQty}`}</span>
      </div>
      <input
        type="text"
        value={query}
        className="focus:outline-none border w-96 text-center rounded ml-2 p-2"
        onChange={(e) => setQuery(e.target.value)}
      />
      <IconButton className="p-4 ml-3" title="Wikimapia search" disabled={loading} onClick={() => searchOnWikimapia()}>
        <SearchIcon />
      </IconButton>
      <IconButton
        className="p-4 ml-3"
        title="Load polygons in current area"
        disabled={loading}
        onClick={() => searchFeaturesByBbox()}
      >
        <DashboardOutlinedIcon />
      </IconButton>
    </>
  )
}
