import { AuthorizationContext } from './AuthorizationContext'
import AuthService from '../../services/auth.service'
import type { PropsWithChildren } from 'react'

export default function AuthorizationGuard({ children }: PropsWithChildren) {
  /**
   * Attempts to handle authoriztion flow.
   * If everything goes ok it will return method to sighout.
   */
  const signOut = AuthService.authorize()
  if (!signOut) {
    return <div>Unauthorized</div>
  }

  return <AuthorizationContext.Provider value={signOut}>{children}</AuthorizationContext.Provider>
}
