import { Component } from 'react'
import Loading from './Loading'
import { toast } from 'react-toastify'
import * as cheerio from 'cheerio'
import { proxyClient } from '../../api/proxyClient'

export type ProxyIFrameProps = {
  src: string
  highlight?: string[]
  className?: string
  onHighlight?: (word: string[]) => void
}
export type ProxyIFrameState = {
  content: string | null
  finalContent: string | null
  isLoading: boolean
}

export class ProxyIFrame extends Component<ProxyIFrameProps, ProxyIFrameState> {
  private proxy

  constructor(props: ProxyIFrameProps) {
    super(props)
    this.state = {
      content: null,
      finalContent: null,
      isLoading: true,
    }
    this.proxy = proxyClient
  }

  async componentDidMount() {
    try {
      let content = await this.proxy.get('/' + this.props.src).catch((err) => {
        toast.warn(err.message)
        return err?.response?.data
      })

      const url = new URL(this.props.src.substring(this.props.src.lastIndexOf('http')))

      if (content) {
        const PROXY_URL = this.proxy.getUri()

        content = content
          .replaceAll('"https://', `"${PROXY_URL}/`)
          .replaceAll('"http://', `"${PROXY_URL}/`)
          .replaceAll('"/', `"${PROXY_URL}/${url.hostname}/`)
      }

      this.setState({
        content,
        isLoading: false,
      })
    } catch (err) {
      toast.error(err?.toString())
      this.setState({ isLoading: false })
    }
  }

  highlight(contentInput: string): string {
    console.log({
      hili: this.props.highlight,
    })

    let content = contentInput
    // TODO wait until documents become loaded
    if (this.props.highlight) {
      const words = Array.from(new Set(this.props.highlight)).map((word) => word.trim())
      const $ = cheerio.load(content)

      const header = $('body > div').first()
      if (header.attr('id')?.indexOf('__google-cache-hdr') !== -1) {
        header.css('display', 'none')
      }

      $('h1,h2,h3,h4,h5,h6,a,p,span,i,li').each(function () {
        let text = $(this).text()
        let needsReplacement = false
        words.forEach((word) => {
          if (text.toLocaleLowerCase().indexOf(word.toLocaleLowerCase()) !== -1) {
            text = text.replaceAll(
              new RegExp(`${word.replaceAll(/[.*+?^${}()|[\]\\]/gu, '\\$&')}`, 'gmiu'),
              `<highlightWord>${word}</highlightWord>`,
            )
            needsReplacement = true
          }
        })
        if (needsReplacement) {
          $(this).html(text)
        }
      })

      $('head').append(
        `<style type="text/css">
        highlightWord {
          background-color:#ff0;
          padding:2px;
          text:black
        }          
        <style>`,
      )

      content = $.html()
    }

    if (this.props.onHighlight) {
      this.props.onHighlight(this.props.highlight?.filter((w) => content.indexOf(w) !== -1) || [])
    }

    return content
  }

  render() {
    return (
      <div className={this.props.className}>
        {!this.state?.isLoading ? (
          <iframe
            className="w-full h-full"
            sandbox="allow-scripts"
            srcDoc={this.highlight(this.state.content || '...')}
            onLoad={() => {}}
          />
        ) : (
          <div className="w-full">
            <Loading />
          </div>
        )}
      </div>
    )
  }
}
