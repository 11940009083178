import { validateSearchParamsObject } from './searchParametersValidation'

export type SearchParameterValue = string | number

export type WellKnownSearchParams = {
  name?: string
  address?: string
  inn?: string
  ogrn?: string
  lat?: number
  lon?: number
}

export type SearchUtilitySearchParams = WellKnownSearchParams & Record<string, SearchParameterValue | undefined>

export const parseSearchParamsObject = (urlSearchParams: URLSearchParams): SearchUtilitySearchParams => {
  const rawObject = Object.fromEntries(urlSearchParams.entries())
  const lat = rawObject['lat'] !== undefined ? Number.parseFloat(rawObject['lat']) : undefined
  const lon = rawObject['lon'] !== undefined ? Number.parseFloat(rawObject['lon']) : undefined

  return { ...rawObject, lat, lon }
}

export const parseValidSearchParams = (urlSearchParams: URLSearchParams) => {
  const rawSearchParams = parseSearchParamsObject(urlSearchParams)
  const { validationMessagesMap } = validateSearchParamsObject(rawSearchParams)

  const validEntries = Object.entries(rawSearchParams).filter(
    ([key, value]) => !validationMessagesMap.has(key) && value !== undefined,
  )

  const validedSearchParams = Object.fromEntries(validEntries)

  return {
    searchParams: validedSearchParams as SearchUtilitySearchParams,
    validationMessagesMap,
  }
}
