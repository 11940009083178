import Joi from 'joi'
import type { ValidationErrorItem } from 'joi'
import type { SearchUtilitySearchParams } from './searchParametersParser'

export type ValidationResult = {
  isValid: boolean
  validationMessagesMap: Map<string, string[]>
}

const EMPTY_STRING_MESSAGE = 'Не може бути порожнім рядком'
const NOT_A_STRING_MESSAGE = 'Повинно бути рядком'
const NOT_A_NUMBER_MESSAGE = 'Повинно бути числом'

export const NAME_MAX_LENGHT = 150

const queryParametersSchema = Joi.object({
  name: Joi.string()
    .optional()
    .max(NAME_MAX_LENGHT)
    .messages({
      'string.base': NOT_A_STRING_MESSAGE,
      'string.empty': EMPTY_STRING_MESSAGE,
      'string.max': `Довжина не може бути більше за ${NAME_MAX_LENGHT} символів`,
    }),
  address: Joi.string().optional().max(150).messages({
    'string.base': NOT_A_STRING_MESSAGE,
    'string.empty': EMPTY_STRING_MESSAGE,
    'string.max': 'Довжина не може бути більше за 150 символів',
  }),
  inn: Joi.string()
    .optional()
    .pattern(/^\d{10}$/)
    .messages({
      'string.base': NOT_A_STRING_MESSAGE,
      'string.empty': EMPTY_STRING_MESSAGE,
      'string.pattern.base': 'Повинно складатися рівно з 10 цифр',
    }),
  ogrn: Joi.string()
    .optional()
    .pattern(/^\d{13}$/)
    .messages({
      'string.base': NOT_A_STRING_MESSAGE,
      'string.empty': EMPTY_STRING_MESSAGE,
      'string.pattern.base': 'Повинно складатися рівно з 13 цифр',
    }),
  lat: Joi.number().optional().min(-90).max(90).messages({
    'number.base': NOT_A_NUMBER_MESSAGE,
    'number.min': 'Мінімальне допустиме значення -90',
    'number.max': 'Максимальне допустиме значення 90',
  }),
  lon: Joi.number().optional().min(-180).max(180).messages({
    'number.base': NOT_A_NUMBER_MESSAGE,
    'number.min': 'Мінімальне допустиме значення -180',
    'number.max': 'Максимальне допустиме значення 180',
  }),
})

export const validateSearchParamsObject = (searchParams: SearchUtilitySearchParams): ValidationResult => {
  const validationResult = queryParametersSchema.validate(searchParams, {
    abortEarly: false,
    allowUnknown: true,
  })

  const validationDetails = (validationResult.error?.details || []) as ValidationErrorItem[]

  const validationMessagesMap = validationDetails
    .map(({ context, message }) => {
      const key = context?.key === undefined ? '$noContext' : context.key
      return { key, message }
    })
    .reduce(
      (errorsMap, { key, message }) => {
        if (!errorsMap.has(key)) {
          errorsMap.set(key, [])
        }

        ;(errorsMap.get(key) as string[]).push(message)

        return errorsMap
      },
      new Map() as Map<string, string[]>,
    )

  return {
    isValid: validationMessagesMap.size === 0,
    validationMessagesMap,
  }
}
