import { WikimapiaSearchResultItem } from '../types'

export type SearchResultsAction =
  | { type: 'filter'; filter: string }
  | { type: 'update'; value: WikimapiaSearchResultItem[]; filter: string }
  | { type: 'delete' }

export type SearchResultsState = {
  all: WikimapiaSearchResultItem[]
  filtered: WikimapiaSearchResultItem[]
}

export const defaultSearchResultsState: SearchResultsState = {
  all: [],
  filtered: [],
}

export function searchResultsReducer(state: SearchResultsState, action: SearchResultsAction) {
  switch (action.type) {
    case 'filter': {
      return {
        all: state.all,
        filtered: state.all.filter((item) => item.address.includes(action.filter)),
      }
    }
    case 'update': {
      return {
        all: action.value,
        filtered: action.value.filter((item) => item.address.includes(action.filter)),
      }
    }
    case 'delete': {
      return { all: [], filtered: [] }
    }
    default: {
      throw new Error('Unknown action type')
    }
  }
}
