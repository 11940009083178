import App from './App'
import AuthorizationGuard from './components/AuthorizationGuard/AuthorizationGuard'

export default function AppWithAuthorization() {
  return (
    <AuthorizationGuard>
      <App />
    </AuthorizationGuard>
  )
}
