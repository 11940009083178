import { getSearchParameter, deleteSearchParameter } from '../utils/searchParametersManager'

const LOGIN_PAGE_URL = process.env.REACT_APP_AUTH_URL || 'http://localhost:3001'
const SESSION_TOKEN_NAME = 'auth_token'
const TOKEN_QUERY_PARAMETER = 'authorizationToken'

/**
 * TODO this code has beend done in a hurry, so consider more elegant solution if possible
 */
export default class AuthService {
  public static getAuthToken() {
    return (localStorage.getItem(SESSION_TOKEN_NAME) as string) || process.env.REACT_DEFAULT_AUTH_TOKEN
  }

  public static signOut() {
    localStorage.removeItem(SESSION_TOKEN_NAME)
    window.location.reload()
  }

  public static authorize() {
    const signOut = () => AuthService.signOut()
    const redirectToAuth = () => {
      const authPageUrl = LOGIN_PAGE_URL
      const currentLocation = window.location.href
      window.location.replace(`${authPageUrl}?redirectTo=${currentLocation}`)
    }

    const sessionToken = getSearchParameter(TOKEN_QUERY_PARAMETER)
    if (sessionToken !== undefined) {
      localStorage.setItem(SESSION_TOKEN_NAME, `${sessionToken}`.trim())
      deleteSearchParameter(TOKEN_QUERY_PARAMETER)
    }

    // attempt to retrieve auth token if exists
    const accessToken = AuthService.getAuthToken()

    if (accessToken) {
      return signOut
    } else {
      redirectToAuth()
    }
    return false
  }
}
