import { createContext } from 'react'
import { WikimapiaAPI } from './utils/WikimapiaAPI'
import { WikimapiaAPIKey } from './utils/WikimapiaAPIKey'

type WikimapiaSearchContext = {
  api: WikimapiaAPI
}

export const WikimapiaSearchContext = createContext<WikimapiaSearchContext>({
  api: new WikimapiaAPI(new WikimapiaAPIKey()),
})
