import React, { useContext, useEffect, useReducer, useRef, useState } from 'react'
import * as turf from '@turf/turf'
import { IconButton } from '@mui/material'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import { Feature } from 'geojson'
import 'leaflet/dist/leaflet.css'
import wikiIcon from '../../Maps/icons/wiki-icon.png'
import Loading from '../../common/Loading'
import { LoggerService } from '../../common/logger'
import { deepFreeze } from '../../../utils/objectHelper'
import { ShowNothing } from './ShowNothing'
import { WikimapiaSearchDetails } from './WikimapiaSearchDetails'
import { WikimapiaSearchHistory } from './WikimapiaSearchHistory'
import { WikimapiaSearchResult } from './WikimapiaSearchResult'
import { WikimapiaSearchControls } from './WikimapiaSearchControls'
import { bboxResultsReducer, defaultBboxResultsState } from '../reducers/bbox-results.reducer'
import { searchResultsReducer, defaultSearchResultsState } from '../reducers/search-results.reducer'
import { WikimapiaSearchContext } from '../wikimapia-search.context'
import {
  WikimapiaBoxItem,
  WikimapiaSearchItem,
  WikimapiaSearchResultItem,
  WikimapiaBoxResultItem,
  WikimapiaObjectDetail,
} from '../types'

type WikimapiaSearchProps = {
  query: string
  filter: string
  lat: number
  lon: number
  bbox?: string
  onSelectPosition: (lat: number, lon: number) => any
  onSearch: (geoJsonFeature: Feature[]) => any
}

export const SearchType = deepFreeze({
  SEARCH: 'search',
  BBOX: 'bbox',
} as const)
const CATEGORIES = deepFreeze([
  { name: 'Military', key: 516 },
  { name: 'Intelligence agency', key: 9471 },
  { name: 'Law enforcement agency', key: 59020 },
] as const)
const MAX_PAGE_COUNT = 3
const SEARCH_RESULT_LIMIT = 100
const logger = new LoggerService('Wikimapia')

export default function WikimapiaSearchComponent(props: WikimapiaSearchProps) {
  const parentQueryRef = useRef(props.query)
  const { api } = useContext(WikimapiaSearchContext)

  const [open, setOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [searchFilter, setSearchFilter] = useState(props.filter || props.query.trim())
  const [searchType, setSearchType] = useState<(typeof SearchType)[keyof typeof SearchType]>(SearchType.SEARCH)
  const [selected, setSelected] = useState<WikimapiaSearchResultItem | WikimapiaBoxResultItem | null>(null)

  const [searchResults, dispatchSearchResults] = useReducer(searchResultsReducer, defaultSearchResultsState)
  const [bboxResults, dispatchBboxResults] = useReducer(bboxResultsReducer, defaultBboxResultsState)

  const [query, setQuery] = useState<string>(props.query.trim())
  const [addressFilter, setAddressFilter] = useState<string>('')
  const [bboxFilter, setBboxFilter] = useState<string>('')

  useEffect(() => {
    if (props.query && parentQueryRef.current !== props.query) {
      parentQueryRef.current = props.query

      setQuery(props.query)
      searchOnWikimapia()
    }
  }, [props.query])

  function onSelectItem(item: WikimapiaSearchResultItem | WikimapiaBoxResultItem): void {
    if (item !== selected) {
      props.onSelectPosition(item.lat, item.lon)

      if (selected?.title === item.title) {
        return
      }

      setSelected(item)
    }
  }

  function onFilter(filter: string): void {
    setAddressFilter(filter)
    dispatchSearchResults({ type: 'filter', filter: filter })
  }

  function onBboxFilter(filter: string): void {
    setBboxFilter(filter)
    dispatchBboxResults({ type: 'filter', filter: filter })
  }

  function switchSearchType(): void {
    setSearchType(searchType === SearchType.SEARCH ? SearchType.BBOX : SearchType.SEARCH)
  }

  /**
   * On click searchOnWikiMapia
   */
  async function searchOnWikimapia(): Promise<void> {
    const results: WikimapiaSearchResultItem[] = []

    let page = 1
    let hasNextPage = false

    if (!query) {
      console.log('Query string is empty.')
      return
    }

    const idList = new Set()
    const queryAlternatives = query.split(';')
    let searchQuery = queryAlternatives.shift() as string

    setLoading(true)
    dispatchSearchResults({ type: 'delete' })
    setSelected(null)
    setSearchType(SearchType.SEARCH)

    // loop of requests
    do {
      logger.info(`Wikimapia: Attempt with query ${searchQuery}`)
      hasNextPage = false

      console.log(`Request by "${searchQuery}", page: "${searchQuery}"...`)
      try {
        const foundUrlWithId = searchQuery.match(/wikimapia\.org\/(\d*)/)?.at(1)

        if (foundUrlWithId) {
          console.log(`Request by ID "${foundUrlWithId}", page: "${page}"...`)

          const foundItem = await api.getObjectById(Number(foundUrlWithId))

          if (!idList.has(foundItem.id)) {
            idList.add(foundItem.id)

            results.push({
              title: foundItem.title,
              address: `${foundItem.location.country} ${foundItem.location.city || foundItem.location.place || foundItem.location.village}`,
              lon: foundItem.location.lon,
              lat: foundItem.location.lat,
              zoom: foundItem.location.zoom,
              id: foundItem.id,
              geoJsonFeature: createGeoJSONFeature(foundItem),
            })
          }
        } else {
          console.log(`Request by "${searchQuery}", page: "${searchQuery}"...`)

          const response = await api.search({
            query: searchQuery,
            page,
            count: SEARCH_RESULT_LIMIT,
            lat: props.lat,
            lon: props.lon,
          })

          // push to results array of GeoJSON created by each response item
          if (response?.folder?.length > 0) {
            response.folder.forEach((item) => {
              if (idList.has(item.id)) return

              idList.add(item.id)
              results.push({
                title: item.name,
                address: `${item.location.country} ${item.location.place}`,
                lon: item.location.lon,
                lat: item.location.lat,
                zoom: 16,
                id: item.id,
                geoJsonFeature: createGeoJSONFeature(item),
              })
            })
          }

          // if result is empty or result empty by filter use alternative query else use pagination if it is possibly
          if (
            queryAlternatives?.length > 0 &&
            (response.folder?.length === 0 ||
              (addressFilter && results.filter(({ address }) => address.includes(addressFilter))?.length === 0))
          ) {
            searchQuery = queryAlternatives.shift() as string

            hasNextPage = true
            page = 1
          } else {
            hasNextPage = page * SEARCH_RESULT_LIMIT < response.found
            page++
          }
        }
      } catch (e) {
        console.error(e)
        logger.error(String(e))
        setLoading(false)
        return
      }
    } while (hasNextPage && page <= MAX_PAGE_COUNT)

    // push GeoJSON features to parent
    props.onSearch(
      results.reduce((result, { geoJsonFeature }) => {
        geoJsonFeature && result.push(geoJsonFeature)
        return result
      }, [] as Feature[]),
    )

    setSelected(null)
    setLoading(false)
    setOpen(results.some((item) => item.address.includes(addressFilter)))
    setSearchFilter(searchQuery)
    dispatchSearchResults({ type: 'update', filter: addressFilter, value: results })

    if (searchResults.filtered.length === 1) {
      onSelectItem(searchResults.filtered[0])
    }

    logger.success(
      results?.length > 0 ? `Prepare response. Total results count "${results?.length}"` : 'Result is empty',
    )
  }

  async function searchFeaturesByBbox(): Promise<void> {
    if (!props.bbox) {
      logger.warn('Bbox is empty')
      return
    }

    const results: WikimapiaBoxResultItem[] = []

    setLoading(true)
    setSearchType(SearchType.BBOX)
    setSelected(null)

    await Promise.all(
      CATEGORIES.map(async ({ name, key }) => {
        let page = 1
        let hasNextPage = false

        do {
          try {
            hasNextPage = false
            logger.info(`Request by "${name}" category, page: "${page}"...`)

            const response = await api
              .getBox({
                bbox: props.bbox as string,
                page,
                count: SEARCH_RESULT_LIMIT,
                category: key,
              })
              .catch((e) => {
                console.error(e)
                logger.error(e?.toString())
              })

            if (!response) {
              return
            }

            // push to results array of GeoJSON created by each response item
            if (response.folder?.length > 0) {
              const features = response.folder.map<WikimapiaBoxResultItem>((item) => ({
                title: item.name,
                lon: item.location.lon,
                lat: item.location.lat,
                zoom: 16,
                id: Number(item.id),
                geoJsonFeature: createGeoJSONFeature(item),
              }))

              results.concat(features)
            }

            hasNextPage = page * SEARCH_RESULT_LIMIT < response.found
            page++
          } catch (e) {
            console.error(e)
            logger.error(String(e))
            return
          }
        } while (hasNextPage && page <= MAX_PAGE_COUNT)
      }),
    )

    props.onSearch(
      results.reduce((result, { geoJsonFeature }) => {
        geoJsonFeature && result.push(geoJsonFeature)
        return result
      }, [] as Feature[]),
    )

    setLoading(false)
    dispatchBboxResults({ type: 'update', filter: bboxFilter, value: results })

    logger.success(`Load complete! Added ${results?.length} features`)
  }

  return (
    <div className="relative flex w-auto z-[999] bg-white">
      <div
        className={`bg-white flex flex-col items-center absolute bottom-0 left-1 p-2 border rounded-t-xl ${
          open ? 'hidden' : 'visible'
        }`}
      >
        <div className="flex items-center">
          <div className="flex align-top justify-start">
            <IconButton className={'w-10 h-10'} onClick={() => setOpen(!open)} title="Expand search">
              <ArrowDropUpIcon />
            </IconButton>
          </div>
          <WikimapiaSearchControls
            loading={loading}
            searchType={searchType}
            resultsQty={searchType === SearchType.SEARCH ? searchResults.all.length : bboxResults.all.length}
            filteredResultsQty={
              searchType === SearchType.SEARCH ? searchResults.filtered.length : bboxResults.filtered.length
            }
            query={query}
            setQuery={setQuery}
            searchOnWikimapia={searchOnWikimapia}
            searchFeaturesByBbox={searchFeaturesByBbox}
          />
        </div>
      </div>

      <div className={`flex flex-row w-full h-[32rem] overflow-hidden m-1 ${open ? 'visible' : 'hidden'}`}>
        {/*SEARCH SECTION*/}
        <div className="flex flex-col w-1/3 h-full relative">
          {/*CONTROL*/}
          <div className="flex flex-row w-full h-auto">
            <div className="flex flex-col items-center justify-around">
              <IconButton className={'w-10 h-10'} onClick={() => setOpen(!open)} title="Expland search">
                <ArrowDropDownIcon />
              </IconButton>
              <IconButton
                className={'w-8 h-8'}
                onClick={() => switchSearchType()}
                title="Change to show search/bbox results"
              >
                <img src={wikiIcon} alt="yandex" />
              </IconButton>
            </div>
            <div className="flex flex-col">
              <div className="flex items-center">
                <WikimapiaSearchControls
                  loading={loading}
                  searchType={searchType}
                  resultsQty={searchType === SearchType.SEARCH ? searchResults.all.length : bboxResults.all.length}
                  filteredResultsQty={
                    searchType === SearchType.SEARCH ? searchResults.filtered.length : bboxResults.filtered.length
                  }
                  query={query}
                  setQuery={setQuery}
                  searchOnWikimapia={searchOnWikimapia}
                  searchFeaturesByBbox={searchFeaturesByBbox}
                />
              </div>
              <div className="float-right py-2">
                <FilterAltOutlinedIcon className="absolute h-16 my-2" />
                {searchType === SearchType.SEARCH && (
                  <input
                    type="text"
                    placeholder="Search filter"
                    value={addressFilter}
                    className="focus:outline-none border w-46 p-2 text-center rounded"
                    onChange={(e) => onFilter(e.target.value)}
                  />
                )}
                {searchType === SearchType.BBOX && (
                  <input
                    type="text"
                    placeholder="Features filter"
                    value={bboxFilter}
                    className="focus:outline-none border w-46 p-2 text-center rounded"
                    onChange={(e) => onBboxFilter(e.target.value)}
                  />
                )}
              </div>
            </div>
          </div>
          {/*RESULT LIST*/}
          <div className="flex flex-col w-full h-full m-1 overflow-y-auto">
            {loading && <Loading />}
            {!loading &&
              ((searchType === SearchType.SEARCH && searchResults.filtered.length === 0) ||
                (searchType === SearchType.BBOX && bboxResults.filtered.length === 0)) && <ShowNothing />}
            {!loading &&
              ((searchType === SearchType.SEARCH && searchResults.filtered.length > 0) ||
                (searchType === SearchType.BBOX && bboxResults.filtered.length > 0)) &&
              (searchType === SearchType.SEARCH ? searchResults.filtered : bboxResults.filtered).map((item) => (
                <WikimapiaSearchResult
                  key={item.id}
                  filter={searchFilter}
                  item={item}
                  onClick={() => onSelectItem(item)}
                />
              ))}
          </div>
        </div>
        {/*DETAILS SECTIONS*/}
        <div className="flex flex-col w-1/3 h-full relative m-2">
          {selected ? (
            <WikimapiaSearchDetails searchResultID={selected.id} searchFilter={searchFilter} />
          ) : (
            <ShowNothing />
          )}
        </div>
        {/*HISTORY SECTION*/}
        <div className="flex w-1/3 h-full relative m-2">
          {selected ? (
            <WikimapiaSearchHistory searchResultID={selected.id} searchFilter={searchFilter} />
          ) : (
            <ShowNothing />
          )}
        </div>
      </div>
    </div>
  )
}

/**
 * Creates valid GeoJSON based on data from wikimapia.
 *
 * @param {(WikimapiaBoxItem|WikimapiaSearchItem)} item
 * @returns {(Feature|null)}
 */
function createGeoJSONFeature(item: WikimapiaBoxItem | WikimapiaSearchItem | WikimapiaObjectDetail): Feature | null {
  const address: string[] = []

  if ('country' in item.location && item.location.country) {
    address.push(item.location.country)
  }

  if ('place' in item.location && item.location.place) {
    address.push(item.location.place)
  }

  const properties = {
    'wikimapia:id': item.id,
    ...('url' in item && item.url && { url: item.url }),
    ...('name' in item && { name: item.name }),
    address: address?.length > 0 ? address.join(' ') : '-',
  }

  try {
    return turf.polygon(
      [
        [
          [item.polygon[item.polygon?.length - 1].x, item.polygon[item.polygon?.length - 1].y],
          ...item.polygon.map(({ x, y }) => [x, y]),
        ],
      ],
      properties,
    )
  } catch (err) {
    console.log('Error while creating GeoJSON', err)
  }

  return null
}
