import { CreateMapOptions } from '../../MapsWrapper/types'
import { Map, tileLayer } from 'leaflet'
import { ProviderInterface } from './index'

export default class WikimapiaProvider implements ProviderInterface {
  public async create(options: CreateMapOptions): Promise<Map> {
    return new Map(options.id, options).addLayer(
      tileLayer('http://{s}{hash}.wikimapia.org/?x={x}&y={y}&zoom={z}&r=7071412&type=&lng=1', {
        hash: (data: { x: number; y: number }) => (data.x % 4) + (data.y % 4) * 4,
        subdomains: 'i',
        attribution: '&copy; <a href="http://wikimapia.org" target="_blank">Wikimapia.org</a>',
      } as any),
    )
  }
}
