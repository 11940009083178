import React, { useState } from 'react'

export function Tab({ children, activeTab, currentTab, setActiveTab }: any) {
  return (
    <>
      <div
        className={`mx-1 px-3 py-3 rounded cursor-pointer w-32 float-left text-center
        ${activeTab === currentTab ? 'bg-gray-400 text-white' : 'bg-white hover:bg-gray-100'}`}
        onClick={() => setActiveTab(currentTab)}
      >
        {children}
      </div>
    </>
  )
}

Tab.displayName = 'Tab'

export function Tabs({ children, className, onInit }: any) {
  function findActiveTab(a: any) {
    return a.reduce((accumulator: any, currentValue: any, i: number) => {
      if (currentValue.props.active) {
        return i
      }

      return accumulator
    }, 0)
  }

  function tabValidator(tab: any) {
    return tab.type.displayName === 'Tab' ? true : false
  }

  const [activeTab, setActiveTab] = useState(findActiveTab(children))

  const tabs = children.reduce((accumulator: any, item: any, i: number) => {
    accumulator[item.props.id] = i
    return accumulator
  }, {})

  onInit && onInit((id: string) => setActiveTab(tabs[id]))

  return (
    <>
      <div className={`${className}`}>
        {children.map((item: any, i: number) => {
          return (
            <div key={`tab-button-wr-${i}`}>
              {tabValidator(item) && (
                <Tab key={`tab-button-${i}`} currentTab={i} activeTab={activeTab} setActiveTab={setActiveTab}>
                  {item.props.title}
                </Tab>
              )}
            </div>
          )
        })}
      </div>
      <div className="p-0 flex w-full h-full">
        {children.map((item: any, i: number) => {
          return (
            <div key={`tab-${i}`} className={` ${i === activeTab ? 'visible w-full h-full' : 'hidden'}`}>
              {item.props.children}
            </div>
          )
        })}
      </div>
    </>
  )
}
