import { InterceptorConfig } from '../utils/axiosFactory'

export const useCorrelationID: InterceptorConfig = {
  request: {
    onFulfilled: (config) => {
      config.headers.set('X-Correlation-ID', crypto.randomUUID())

      return config
    },
    options: { synchronous: true },
  },
}
