import {
  useContext,
  Attributes,
  ComponentType,
  PropsWithChildren,
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
} from 'react'
import { PublicIPContext, AccessUnsafeResourceEvent } from '../../Contexts'

type UnsafeResourceComponentProps<T> = PropsWithChildren<AnchorHTMLAttributes<T> | ButtonHTMLAttributes<T>> & Attributes

export function unsafeResource<T, P extends UnsafeResourceComponentProps<T>>(
  Component: ComponentType<P> | string,
  displayName: string,
  defaultProps?: P,
) {
  function DerivedComponent(props: P) {
    const { checkPublicIP } = useContext(PublicIPContext)

    return (
      <Component
        {...{ ...defaultProps, ...props }}
        onClick={(e: AccessUnsafeResourceEvent<T>) => checkPublicIP<T>(e, props.onClick)}
      >
        {props.children}
      </Component>
    )
  }

  DerivedComponent.displayName = displayName

  return DerivedComponent
}
