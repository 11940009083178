import React from 'react'
import { Autocomplete, TextField } from '@mui/material'
import axios from 'axios'
import { latLng, LatLng } from 'leaflet'

interface OSMSearchProps {
  onSelect: (event: any, latLng: LatLng, description?: string) => void
}
interface OSMSearchState {
  options: OptionType[]
  value: OptionType | null
  inputValue: string
}

interface OptionType {
  properties: {
    name: string
    display_name: string
    addresstype: string
  }
  coordinates: LatLng
}

const osmUrl = 'https://nominatim.openstreetmap.org/search'
export default class OSMSearchComponent extends React.Component<OSMSearchProps, OSMSearchState> {
  state: OSMSearchState = {
    options: [],
    value: null,
    inputValue: '',
  }

  private tryGetLanLng = (v: string): LatLng | null => {
    const data = v.split(',')
    if (data.length !== 2) return null

    const [lat, lng] = data.map((item) => parseFloat(item.trim()))

    if (
      !isNaN(lat) &&
      !isNaN(lng) &&
      ((lat > -180 && lat < 180 && lng > -90 && lng < 90) || (lng > -180 && lng < 180 && lat > -90 && lat < 90))
    )
      return latLng(lat, lng)

    return null
  }
  private delay = (function () {
    let delayTimer = 0
    return function (callback: () => void, ms: number) {
      clearTimeout(delayTimer)
      delayTimer = Number(setTimeout(callback, ms))
    }
  })()
  private delaySearch = () => this.delay(() => this.search(this.state.inputValue), 700)
  private search = (value: string) => {
    if (this.state.inputValue.length > 2) {
      const c = this.tryGetLanLng(value)

      if (c !== null) {
        const options = [
          {
            properties: {
              name: [c.lat, c.lng].join(),
              display_name: [c.lat, c.lng].join(', '),
              addresstype: [c.lat, c.lng].join(', '),
            },
            coordinates: c,
          },
          {
            properties: {
              name: [c.lng, c.lat].join(', '),
              display_name: [c.lng, c.lat].join(', '),
              addresstype: [c.lng, c.lat].join(', '),
            },
            coordinates: latLng(c.lng, c.lat),
          },
        ]
        setTimeout(() => this.setOptions(options))
        return
      }

      axios
        .get(osmUrl, {
          params: {
            q: value,
            format: 'geojson',
            limit: 5,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            if (response.data.features.length === 0) return

            const options: Array<OptionType> = response.data.features.map((val: any) => {
              const [lng, lat] = val.geometry.coordinates
              return {
                properties: {
                  name: val.properties.name,
                  display_name: val.properties.display_name,
                  addresstype: val.properties.addresstype,
                },
                coordinates: latLng(lat, lng),
              }
            })

            this.setOptions(options)
          }
        })
        .catch((error) => console.log(error))
    }
  }
  setInputValue = (newInputValue: string) => {
    this.setState({ inputValue: newInputValue }, this.delaySearch)
  }
  setValue = (newValue: OptionType | null) => {
    this.setState(
      { value: newValue },
      () =>
        this.state.value && this.props.onSelect(null, this.state.value.coordinates, this.state.value.properties.name),
    )
  }
  setOptions = (newOptions: Array<OptionType>) => this.setState({ options: newOptions })

  render() {
    return (
      <div className="search-block">
        <Autocomplete
          id="search-input"
          sx={{ width: 300 }}
          options={this.state.options}
          value={this.state.value}
          onChange={(event: any, newValue: OptionType | null) => {
            this.setOptions(
              newValue
                ? ((): Array<OptionType> => {
                    let exist = false
                    this.state.options.forEach((v) => {
                      if (v.properties.display_name === v.properties.display_name) {
                        exist = true
                      }
                    })
                    return exist ? this.state.options : [newValue, ...this.state.options]
                  })()
                : this.state.options,
            )

            this.setValue(newValue)
          }}
          onInputChange={(event, newInputValue) => {
            this.setInputValue(newInputValue)
          }}
          renderInput={(params) => (
            <TextField {...params} label="Add a location" fullWidth size="small" className="osm-search-input" />
          )}
          getOptionLabel={(option) => {
            return option.properties.display_name === option.properties.addresstype
              ? option.properties.display_name
              : option.properties.display_name + '(' + option.properties.addresstype + ')'
          }}
        />
      </div>
    )
  }
}
