import { useState } from 'react'
import ManageSearchIcon from '@mui/icons-material/ManageSearch'
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks'
import MapIcon from '@mui/icons-material/Map'
import { Tab, Tabs } from './common/Tabs'
import MapsWrapper from './MapsWrapper'
import SearchList from './SearchParser/SearchList'
import LegalDataTable from './LegalDataTable'
import Utils from './common/Utils'
import { getSearchParameter } from '../utils/searchParametersManager'

export default function ApplicationBoard() {
  const name = (getSearchParameter('name') || '') as string
  const inn = (getSearchParameter('inn') || '') as string
  const ogrn = (getSearchParameter('ogrn') || '') as string
  let tab = (getSearchParameter('tab') || '') as string

  const militaryUnitNumber = Utils.getMilUnitNumber(name)
  document.title = militaryUnitNumber || name || 'Shaffner'
  const [wikimapiaSearchQuery, setWikimapiaSearchQuery] = useState(
    militaryUnitNumber && /^\d{4,5}/.test(militaryUnitNumber)
      ? [militaryUnitNumber, `част ${militaryUnitNumber}`, `ч ${militaryUnitNumber}`].join(';')
      : name,
  )

  let tabControl: any
  const goToTab = (tabId: string) => {
    tab = tabId
    tabControl(tabId)
  }

  return (
    <Tabs
      className="w-[28rem] h-16 z-[2] ml-2 z-3 inline-block fixed top-1 left-0"
      onInit={(fn: any) => (tabControl = fn)}
    >
      <Tab
        id="data"
        title={
          <span>
            Search <ManageSearchIcon />
          </span>
        }
        active={!tab || tab === 'search'}
      >
        <div id="info-section" className="flex flex-row">
          <div className="w-full px-10 pt-20 h-screen">
            {name ? (
              <SearchList
                name={name}
                filter={
                  militaryUnitNumber
                    ? Array.from(new Set([name, `в/ч ${militaryUnitNumber}`, militaryUnitNumber])).join(';')
                    : ''
                }
                onItemClick={(item) => {
                  const wikimapiaFounds = item.link.match(/^http(s)?:\/\/(wikimapia\.org\/\d*)\//)
                  if (wikimapiaFounds?.length && wikimapiaFounds[2]) {
                    setWikimapiaSearchQuery(wikimapiaFounds[2])
                    goToTab('maps')
                  }
                }}
              />
            ) : (
              <div className="mt-20 text-3xl text-center text-gray-200">Не знайдено данних</div>
            )}
          </div>
        </div>
      </Tab>
      <Tab
        id="maps"
        title={
          <span>
            Maps <MapIcon />
          </span>
        }
        active={tab === 'map'}
      >
        <div id="map-section" className="flex flex-row absolute z-[-1] -top-0 left-0 bg-gray-100 w-full h-full">
          <MapsWrapper query={wikimapiaSearchQuery} />
        </div>
      </Tab>
      <Tab
        id="legal"
        title={
          <span>
            Legal <LibraryBooksIcon />
          </span>
        }
        active={tab === 'legal'}
      >
        <div id="property-contructor-section" className="flex flex-row">
          <div className="w-full h-full mt-16">
            <LegalDataTable
              name={name}
              inn={inn}
              ogrn={ogrn}
              onLoad={({ address }) => {
                console.log('Onload', address)
              }}
            />
          </div>
        </div>
      </Tab>
    </Tabs>
  )
}
