import { toast } from 'react-toastify'
import { MU_NAME_TO_BASE_NUM_REGEXP } from './constants'

export default class Utils {
  public static copyToClipboard(data: any) {
    try {
      if (typeof data === 'object') {
        navigator.clipboard.writeText(JSON.stringify(data, null, 2))
      } else {
        navigator.clipboard.writeText(data)
      }
      toast.info('Copied to clipboard')
    } catch (err) {
      toast.error(`Error while copy to clipboard ${err?.toString()}`)
      console.error(err)
    }
  }

  public static getMilUnitNumber(name: string): string {
    if (name) {
      const found = name.match(MU_NAME_TO_BASE_NUM_REGEXP)
      if (found) return found[2] || found[0]
    }
    return ''
  }

  public static setObjectToLocalStorage(key: string, data: any) {
    if (!data) return localStorage.setItem(key, data as any)

    try {
      localStorage.setItem(key, JSON.stringify(data))
    } catch (err) {
      console.error(err)
    }
  }

  public static getObjectToLocalStorage(key: string) {
    const data = localStorage.getItem(key)

    if (!data) return data

    try {
      const parsed = JSON.parse(data)
      return parsed
    } catch (err) {
      console.error(err)
    }

    return null
  }

  public static normalizeLatLon(coordinate: string | number): number {
    if (typeof coordinate === 'number') return coordinate

    return Number(coordinate.replace(',', '.'))
  }

  public static removeSchemeFromUrl(url: string): string {
    return url.replace('https://', '').replace('http://', '')
  }
}
