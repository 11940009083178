import { Component, ReactNode } from 'react'
import PreviewIcon from '@mui/icons-material/Preview'
import Modal from '../common/Modal'
import { ProxyIFrame } from '../common/ProxyIFrame'
import { UnsafeResourceLink } from '../common/UnsafeResourceLink'
import { TextField } from '@mui/material'

//TODO: fix typo `hightlight`  -> `highlight`
type ModalPreviewPageProps = { title: string; url: string; filter?: string }
type ModalPreviewPageState = { hightlightWords: string[] }

export class ModalPreviewPage extends Component<ModalPreviewPageProps, ModalPreviewPageState> {
  constructor(props: ModalPreviewPageProps) {
    super(props)
    this.state = {
      hightlightWords: [],
    }
  }

  componentDidMount(): void {
    this.prepareHighlightWords(this.props.filter || '')
  }

  prepareHighlightWords(text: string) {
    return this.setState({
      hightlightWords: text?.trim()?.replaceAll(';', ' ').split(' ') as string[],
    })
  }

  render(): ReactNode {
    return (
      <Modal
        buttonText={<PreviewIcon />}
        buttonCss="h-14 w-14 p-4 mx-1 bg-gray-200 active:bg-gray-400 hover:bg-gray-300 text-black rounded-full float-right hover:cursor-pointer"
        title={
          <div className="w-[74rem] flex flex-row">
            <div className="w-2/5">
              <p className="truncate">{`Cached "${this.props.title}"`}</p>
              <UnsafeResourceLink className="text-blue-400 inline-block w-96 underline truncate" href={this.props.url}>
                {this.props.url}
              </UnsafeResourceLink>
            </div>
            <div className="w-3/5">
              <TextField
                id="filter-outlined-basic"
                label="Filter"
                variant="outlined"
                className="w-full focus:outline-none p-2 -mt-2 float-left border-solid border-2 bg-white"
                type="text"
                defaultValue={this.props.filter}
                onChange={(e) => setTimeout(() => this.prepareHighlightWords(e.target.value), 0)}
                placeholder="Filter"
                inputProps={{ min: 0 }}
              />
            </div>
          </div>
        }
      >
        <ProxyIFrame className="w-full h-[44rem]" src={this.props.url} highlight={this.state.hightlightWords} />
      </Modal>
    )
  }
}
