import { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import 'react-toastify/dist/ReactToastify.css'
import './App.css'
import PublicIPCheckDialog from './components/PublicIp/PublicIPCheckDialog'
import PublicIpGuard from './components/PublicIp/PublicIpGuard'
import Toolbar from './components/Toolbar'
import ApplicationBoard from './components/ApplicationBoard'
import { getValidationMessagesMap } from './utils/searchParametersManager'
import WelcomePage from './components/WelcomePage'

const queryClient = new QueryClient()

function App() {
  const [isAllowedToProceed, setIsAllowedToProceed] = useState(false)

  useEffect(() => {
    const validationMessagesMap = getValidationMessagesMap()
    if (validationMessagesMap.size > 0) {
      const notValidParams = Array.from(validationMessagesMap.keys())
      const notificationId = toast.warn(
        <p>
          Помилки у query-параметрах: <span className="italic">{notValidParams.join(', ')}</span>
        </p>,
      )

      return () => toast.dismiss(notificationId)
    }
  }, [])

  return (
    <>
      <ToastContainer position="bottom-center" className="z-[9999]" closeOnClick={true} limit={8} />
      <WelcomePage>
        <QueryClientProvider client={queryClient}>
          <PublicIPCheckDialog>
            <main className="items-center h-auto">
              <Toolbar onAllowToProceed={() => setIsAllowedToProceed(true)} />
              <PublicIpGuard isAllowedToProceed={isAllowedToProceed}>
                <ApplicationBoard />
              </PublicIpGuard>
            </main>
          </PublicIPCheckDialog>
        </QueryClientProvider>
      </WelcomePage>
    </>
  )
}

export default App
