import { DEFAULT_LAT, DEFAULT_LNG, DEFAULT_ZOOM, PROVIDERS } from '../../Maps/constants'
import Utils from '../../common/Utils'
import { LatLng } from 'leaflet'
import { WIKIMAPIA_APIKEY, WIKIMAPIA_LOGIN, WIKIMAPIA_PASSWORD } from '../../../constants'
import { WMCredentials } from '../components/ConfigurationComponent'
import { getSearchParameter } from '../../../utils/searchParametersManager'

const LAST_POSITION_KEY = 'last_position'
const YANDEX_API_KEY = 'd268f24e-8ead-4eb8-92dc-9ae15020102f1'
export const getInitialData = () => {
  const {
    lat = DEFAULT_LAT,
    lng = DEFAULT_LNG,
    zoom = DEFAULT_ZOOM,
    desc = 'Initial point',
  } = Utils.getObjectToLocalStorage(LAST_POSITION_KEY) || {}

  const name = (getSearchParameter('name') || '') as string
  const query = Utils.getMilUnitNumber(name)

  if (!Utils.getObjectToLocalStorage(WIKIMAPIA_APIKEY) || !Utils.getObjectToLocalStorage(WIKIMAPIA_PASSWORD)) {
    // Set default credentials
    Utils.setObjectToLocalStorage(
      WIKIMAPIA_APIKEY,
      '8ADB5F9E-44F32DB2-DA78D53E-EC8294B1-8C0FA7A0-ED0B80C4-9BA972A7-4051A93',
    )
    Utils.setObjectToLocalStorage(WIKIMAPIA_LOGIN, 'negiyih571@soebing.com')
    Utils.setObjectToLocalStorage(WIKIMAPIA_PASSWORD, 'h94w7h9tgh7p74t')
  }

  return {
    initPosition: {
      lat: Utils.normalizeLatLon(getSearchParameter('lat') || lat),
      lng: Utils.normalizeLatLon(getSearchParameter('lon') || lng),
      zoom: Number(getSearchParameter('zoom') || zoom),
      desc: getSearchParameter('address') || desc,
    },
    query,
    filter: query,
    wikimapia: {
      key: Utils.getObjectToLocalStorage(WIKIMAPIA_APIKEY) as string,
      login: Utils.getObjectToLocalStorage(WIKIMAPIA_LOGIN) as string,
      password: Utils.getObjectToLocalStorage(WIKIMAPIA_PASSWORD) as string,
    },
    providers: PROVIDERS,
    yandex: { key: YANDEX_API_KEY },
  }
}

export const storePosition = (position: LatLng, zoom: number, desc?: string) => {
  Utils.setObjectToLocalStorage(LAST_POSITION_KEY, {
    lat: position.lat,
    lng: position.lng,
    zoom,
    desc,
  })
}

export const storeWMCredentials = (wikimapia: WMCredentials) => {
  Utils.setObjectToLocalStorage(WIKIMAPIA_APIKEY, wikimapia.key)
  Utils.setObjectToLocalStorage(WIKIMAPIA_LOGIN, wikimapia.login)
  Utils.setObjectToLocalStorage(WIKIMAPIA_PASSWORD, wikimapia.password)
}
