import { AxiosError } from 'axios'
import { toast } from 'react-toastify'
import { InterceptorConfig } from '../utils/axiosFactory'
import AuthService from '../../services/auth.service'

export const useAuthorization: InterceptorConfig = {
  request: {
    onFulfilled: (config) => {
      config.headers.set('Authorization', `Bearer ${AuthService.getAuthToken()}`)

      return config
    },
    options: { synchronous: true },
  },
  response: {
    onRejected: (error: AxiosError) => {
      if (error.response?.status === 401) {
        toast.warn(`Unauthorized`)

        return AuthService.signOut()
      }

      const url = [error.config?.baseURL, error.config?.url].join('')

      console.error(`Error while sending request : ${error.toString()} <br/> ${url}`)

      throw error
    },
    options: { synchronous: true },
  },
}
