import { Component, PropsWithChildren, ReactNode } from 'react'

type HighlightProps = { query: string }

export default class Highlight extends Component<PropsWithChildren<HighlightProps>> {
  render(): ReactNode {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: (this.props.children as string)?.replaceAll(
            this.props.query,
            `<span class="bg-yellow-300 backdrop-opacity-100 text-black">${this.props.query}</span>`,
          ),
        }}
      />
    )
  }
}
