import React, { ReactNode } from 'react'
import { ALLOWED_PROVIDERS } from '../types'
import { LatLng } from 'leaflet'
import WikimapiaSearchComponent from '../../WikimapiaSearch/components/WikimapiaSearchComponent'
import MapsComponent from '../../Maps'
import { getInitialData, storePosition } from '../utils'

type MapsWrapperProps = {
  query?: string
}
type MapsWrapperState = {
  providers: Array<ALLOWED_PROVIDERS>
  query?: string
  filter?: string
  initPosition: { lat: number; lng: number; zoom: number; desc?: string }
  features: Array<any>
  goToPosition?: LatLng
  currentPosition?: LatLng
  currentBbox?: string
}
export default class MapsWrapper extends React.Component<MapsWrapperProps, MapsWrapperState> {
  constructor(props: MapsWrapperProps) {
    super(props)
    this.state = Object.assign(
      {},
      {
        features: [],
        ...getInitialData(),
      },
      this.props.query ? { query: this.props.query } : {},
    )

    const { lat, lng, zoom, desc } = this.state.initPosition
    this.handleLastMarkedPosition(new LatLng(lat, lng), zoom, desc)
  }

  componentDidUpdate(prevProps: Readonly<MapsWrapperProps>) {
    if (this.props.query && prevProps.query !== this.props.query) {
      this.setState({ query: this.props.query })
    }
  }

  protected handleOnMove = (position: LatLng, bbox: string) =>
    this.setState({ currentPosition: position, currentBbox: bbox })

  protected handleLastMarkedPosition = (position: LatLng, zoom: number, desc?: string) =>
    storePosition(position, zoom, desc)

  render(): ReactNode {
    const { providers, query, filter, initPosition, features, goToPosition, currentPosition, currentBbox } = this.state

    return (
      <div className="maps-container flex flex-col relative w-full h-full">
        <MapsComponent
          providers={providers}
          position={initPosition}
          features={features}
          lastMarkedPosition={this.handleLastMarkedPosition.bind(this)}
          currentPosition={this.handleOnMove.bind(this)}
          goToPosition={goToPosition}
        />
        <WikimapiaSearchComponent
          query={query || ''}
          filter={filter || ''}
          lat={currentPosition ? currentPosition.lat : initPosition.lat}
          lon={currentPosition ? currentPosition.lng : initPosition.lng}
          bbox={currentBbox}
          onSelectPosition={(lat: number, lon: number) => this.setState({ goToPosition: new LatLng(lat, lon) })}
          onSearch={(geoJSONFeatures) =>
            this.setState({
              features: [...this.state.features, ...geoJSONFeatures],
            })
          }
        />
      </div>
    )
  }
}
