import { useContext } from 'react'
import { useQuery } from '@tanstack/react-query'
import { UnsafeResourceLink } from '../../common/UnsafeResourceLink'
import Highlight from '../../common/Highlight'
import Loading from '../../common/Loading'
import { ShowNothing } from './ShowNothing'
import { WikimapiaSearchContext } from '../wikimapia-search.context'

type WikimapiaSearchDetailsProps = {
  searchFilter: string
  searchResultID: number
}

export function WikimapiaSearchDetails({ searchResultID, searchFilter }: WikimapiaSearchDetailsProps) {
  const { api } = useContext(WikimapiaSearchContext)
  const { data, error, isLoading } = useQuery({
    queryKey: ['wikimapia-search', 'details', searchResultID],
    queryFn: () => api.getObjectById(searchResultID),
  })

  if (error) {
    return <ShowNothing />
  }

  if (isLoading) {
    return <Loading />
  }

  if (data) {
    const { title, description, location, tags, comments, similarPlaces } = data

    return (
      <div className="flex flex-col h-auto overflow-y-scroll">
        <div className="text-2xl font-normal py-1 px-2">
          <Highlight query={searchFilter}>{title}</Highlight>
        </div>
        <hr />
        {location?.gadm && location?.gadm.length > 0 && (
          <div className="flex items-center justify-start py-1 flex-wrap">
            <span className="font-normal text-sm">Location:&nbsp;</span>
            {location.gadm.slice(1).map((item, index) => (
              <div key={`wk-details-row-${index}`} className="py-2 hover:bg-stone-100 font-extralight text-sm">
                {`${index === 0 ? '' : '/'}${item.translation || item.name}`}
              </div>
            ))}
          </div>
        )}
        {tags?.length > 0 && (
          <div className="flex flex-wrap items-center justify-start py-1">
            <span className="font-normal text-sm">Tags: </span>
            {tags.map((item, index) => (
              <span key={`wk-details-tags-${index}`} className="font-extralight text-sm hover:bg-stone-100">{`${
                index === 0 ? '' : '|'
              }${item.title}`}</span>
            ))}
          </div>
        )}
        <div className="text-base font-light py-1">
          <Highlight query={searchFilter}>{description}</Highlight>
        </div>
        {comments?.length > 0 && (
          <div className="flex flex-col font-extralight text-sm py-3 px-2">
            {comments.map((item, index) => (
              <div key={`wk-details-comment-${index}`} className="flex flex-col w-full h-full p-3">
                <div className="flex items-center justify-between">
                  <div>
                    <span>user:&nbsp;</span>
                    <span>{item.name}</span>
                  </div>
                  <div>
                    {new Date(item.date * 1000).toLocaleString('en-GB', { month: 'long' }) +
                      ' ' +
                      new Date(item.date * 1000).getFullYear()}
                  </div>
                </div>
                <hr />
                <div>
                  <Highlight query={searchFilter}>{item.message}</Highlight>
                </div>
              </div>
            ))}
          </div>
        )}
        {similarPlaces && (
          <div className="flex flex-col">
            <span className="font-normal">Similar places about</span>
            <ul className="pointer">
              {Object.keys(similarPlaces).map((key) => (
                <li key={`wk-detail-similar-${key}`} className="flex flex-wrap">
                  <UnsafeResourceLink className="font-extralight text-sm pointer" href={similarPlaces[key]?.url || ''}>
                    {similarPlaces[key]?.title}
                  </UnsafeResourceLink>
                  &nbsp;&nbsp;&nbsp;
                  <span className="font-light text-base">{similarPlaces[key]?.distance ?? '-'}km</span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    )
  }

  return <ShowNothing />
}
