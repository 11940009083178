import { useContext } from 'react'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import { AuthorizationContext } from './AuthorizationGuard/AuthorizationContext'
import PublicIp from './PublicIp'
import { Input } from './common/Input'
import { getSearchParameter, setSearchParameter } from '../utils/searchParametersManager'
import { Button } from '@mui/material'
import { toast } from 'react-toastify'

export type ToolbarProps = {
  onAllowToProceed: () => void
}

export default function Toolbar({ onAllowToProceed }: ToolbarProps) {
  const signOut = useContext(AuthorizationContext)
  const searchQuery = (getSearchParameter('name') || '') as string

  return (
    <div className="flex flex-row h-16 z-1 backdrop-blur-sm bg-opacity-30 bg-white fixed top-0 left-0 right-0">
      <div className="basis-1/4"></div>
      <div className="basis-2/4">
        <div className="h-14 text-center p-1 align-middle inline-block w-full text-sm">
          <Input
            default={searchQuery}
            onSubmit={(value: string) => {
              if (value && searchQuery !== value) {
                const errorMessages = setSearchParameter('name', value)
                if (errorMessages) {
                  toast.warn(
                    <>
                      <p>Помилка пошукового запиту :</p>
                      <ul>
                        {errorMessages.map((msg, i) => (
                          <li key={i}>{msg}</li>
                        ))}
                      </ul>
                    </>,
                  )
                }
              }
            }}
          />
        </div>
      </div>
      <div className="basis-1/4">
        <div className="flex">
          <div className="basis-1/4"></div>
          <div className="basis-1/2">
            <div className="py-2">
              <PublicIp onSuccess={onAllowToProceed} />
            </div>
          </div>
          <div className="basis-1/4">
            {signOut !== null ? (
              <Button
                variant="outlined"
                onClick={signOut}
                title="Sign out"
                size="large"
                color="error"
                style={{ padding: '12px', margin: '4px' }}
                className="m-2 p-4 float-right"
              >
                <ExitToAppIcon />
              </Button>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}
