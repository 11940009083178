import * as React from 'react'
import { Tab, Tabs, Box } from '@mui/material'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined'
import { useTheme } from '@mui/material/styles'
import MobileStepper from '@mui/material/MobileStepper'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import gif1 from './gifs/1.gif'
import gif2 from './gifs/2.gif'
import gif3 from './gifs/3.gif'
import gif4 from './gifs/4.gif'
import gif5 from './gifs/5.gif'
import gif6 from './gifs/6.gif'
import Examples from './Examples'
import Tools from './Tools'

const steps = [
  {
    label: 'Введіть запит про інформацію, що Ви шукаєте. Звертіть увагу на робочі шаблони.',
    path: gif1,
  },
  {
    label: 'Тут ви можете отримати результати пошуку одразу з кількох ресурсів.',
    path: gif2,
  },
  {
    label: 'Використовуйте інтерактивний фільтр та отримуйте найбільш релевантні результати пошуку.',
    path: gif3,
  },
  {
    label: 'Використовуйте розумну підсвітку тексту для виявлення співпадінь.',
    path: gif3,
  },
  {
    label: 'Спробуйте налаштувати власний набір ресурсів, що цікавлять саме Вас, з наявного переліку.',
    path: gif4,
  },
  {
    label: 'Відсіюйте шум та непотрібні Вам ресурси.',
    path: gif5,
  },
  {
    label:
      'Використовуйте VPN в роботі, проте ми всеж перевіримо чи з`днання встановлене перед тим як перейти на зовнішні посилання.',
    path: gif6,
  },
]

export type TabKey = 'examples' | 'guide'

export type GuideProps = {
  tab?: TabKey
}

export default function Guide(props: GuideProps) {
  const theme = useTheme()
  const [activeStep, setActiveStep] = React.useState(0)
  const [activeTab, setActiveTab] = React.useState(props?.tab || 'examples')
  const maxSteps = steps.length

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  return (
    <Box>
      <Tabs
        aria-label="wrapped label tabs example"
        value={activeTab}
        onChange={(event: React.SyntheticEvent, newValue: string) => setActiveTab(newValue as TabKey)}
      >
        <Tab
          value="examples"
          label={
            <span>
              <ListAltOutlinedIcon /> Можливості
            </span>
          }
          wrapped
        />
        <Tab
          value="guide"
          label={
            <span>
              <HelpOutlineOutlinedIcon /> Довідка
            </span>
          }
        />
      </Tabs>

      <div role="tabpanel" hidden={activeTab !== 'examples'}>
        <div className="py-4">
          <Tools />
        </div>
        <div className="py-4">
          <h2 className="text-xl">Приклади пошукових запитів</h2>
          <Examples />
        </div>
      </div>
      <div role="tabpanel" hidden={activeTab !== 'guide'}>
        <MobileStepper
          variant="text"
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          nextButton={
            <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
              Далі
              {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </Button>
          }
          backButton={
            <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
              {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
              Назад
            </Button>
          }
        />

        <Box sx={{ flexGrow: 1 }}>
          <Paper
            square
            elevation={0}
            sx={{
              display: 'flex',
              alignItems: 'center',
              height: 50,
              pl: 2,
              bgcolor: 'background.default',
            }}
          >
            <text className="text-xl py-4">{steps[activeStep].label}</text>
          </Paper>
          <div className="flex justify-center items-center">
            <Box
              component="img"
              sx={{ width: '100%', p: 2 }}
              src={steps[activeStep].path}
              alt={steps[activeStep].label}
            />
          </div>
        </Box>
      </div>
    </Box>
  )
}
