import { Box, IconButton, Modal, Paper } from '@mui/material'

import InputBase from '@mui/material/InputBase'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import SearchIcon from '@mui/icons-material/Search'
import CloseIcon from '@mui/icons-material/Close'
import { Component } from 'react'
import Guide from '../WelcomePage/Guide'
import { NAME_MAX_LENGHT } from '../../utils/searchParametersValidation'

type InputProps = {
  default: string
  placeholder?: string
  //TODO: remove buttonText, it's not used
  buttonText?: string
  onSubmit: (value: string) => any
}
type InputState = {
  value: string
  showGuideModal: boolean
}

export class Input extends Component<InputProps, InputState> {
  constructor(props: InputProps) {
    super(props)
    this.state = {
      value: props.default || '',
      showGuideModal: false,
    }
  }

  toggleGuideModal() {
    this.setState({ showGuideModal: !this.state.showGuideModal })
  }

  renderInfoModal() {
    return (
      <Modal
        open={this.state.showGuideModal}
        onClose={this.toggleGuideModal.bind(this)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute' as const,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 1100,
            height: 700,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Guide />
          <IconButton
            type="button"
            sx={{ p: '10px', top: '10px', right: '10px', position: 'absolute' }}
            aria-label="Close"
            onClick={this.toggleGuideModal.bind(this)}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Modal>
    )
  }

  render() {
    return (
      <div>
        {this.renderInfoModal()}
        <Paper
          component="form"
          sx={{
            p: '2px 4px',
            display: 'flex',
            height: 52,
            bgcolor: '#f9f9f9',
          }}
          elevation={0}
        >
          <IconButton sx={{ p: '10px' }} aria-label="guide" onClick={this.toggleGuideModal.bind(this)}>
            <InfoOutlinedIcon />
          </IconButton>
          <InputBase
            sx={{
              ml: 1,
              flex: 1,
            }}
            inputProps={{
              min: 0,
              max: NAME_MAX_LENGHT,
              'aria-label': 'search query',
            }}
            defaultValue={this.state.value}
            placeholder={this.props.placeholder || 'Search query'}
            onChange={(event) => this.setState({ value: event.target.value })}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                event.preventDefault()
                if (!this.state.value) return
                this.props.onSubmit(this.state.value)
              }
            }}
            autoFocus
          />
          <IconButton
            type="button"
            sx={{ p: '10px' }}
            aria-label="search"
            onClick={() => this.props.onSubmit(this.state.value)}
          >
            <SearchIcon />
          </IconButton>
        </Paper>
      </div>
    )
  }
}
