import { LAYER_TYPE_STREET, LAYER_TYPE_TERRAIN } from '../constants'
import { CreateMapOptions, LAYER_TYPE } from '../../MapsWrapper/types'
import { control, Map, tileLayer, TileLayer } from 'leaflet'
import { ProviderInterface } from './index'

const LAYER_HOT = 'HOT'

export default class OpenStreetMapProvider implements ProviderInterface {
  public async create(options: CreateMapOptions): Promise<Map> {
    const map = new Map(options.id, options)
    const layers = this.layers()
    map.addLayer(
      layers[
        options.currentLayerType
          ? (options.currentLayerType as typeof LAYER_TYPE_STREET | typeof LAYER_HOT)
          : LAYER_TYPE_STREET
      ],
    )
    control.layers(layers).addTo(map)
    return map
  }
  private layers(): Record<typeof LAYER_TYPE_STREET | typeof LAYER_HOT, TileLayer> {
    return {
      [LAYER_TYPE_STREET]: this.tileLayer(LAYER_TYPE_STREET),
      [LAYER_HOT]: this.tileLayer(LAYER_TYPE_TERRAIN),
    }
  }

  private tileLayer(t: LAYER_TYPE): TileLayer {
    switch (t) {
      case LAYER_TYPE_TERRAIN:
        return tileLayer('https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png', {
          attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
        })
      case LAYER_TYPE_STREET:
      default:
        return tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
          attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
        })
    }
  }
}
