import { Component, PropsWithChildren, ReactNode } from 'react'
import ClearIcon from '@mui/icons-material/Clear'
import Button from './Button'

type ModalProps = {
  children: any
  button?: ReactNode
  buttonText?: string | ReactNode
  buttonCss?: string
  title: string | ReactNode
} // TODO shoduld be proper type;
type ModalState = { showModal: boolean; title: string | ReactNode }

export default class Modal extends Component<PropsWithChildren<ModalProps>, ModalState> {
  constructor(props: ModalProps) {
    super(props)

    this.state = { showModal: false, title: props.title }
  }

  showModal(title: string) {
    this.setState((prev, props) => ({
      showModal: true,
      title: title ? title : props.title,
    }))
  }

  hideModal() {
    this.setState(() => ({
      showModal: false,
    }))
  }

  toggleModal() {
    this.setState((previousState: ModalState) => ({
      showModal: !previousState.showModal,
    }))
  }

  render(): ReactNode {
    return (
      <div className="modal-container inline">
        {this.props.button ? (
          <div onClick={() => this.toggleModal()}>{this.props.button}</div>
        ) : (
          <Button
            className={this.props.buttonCss || `px-2 py-3 border-0 active:bg-gray-400 hover:bg-gray-200`}
            onClick={() => this.toggleModal()}
          >
            {this.props.buttonText || 'Open modal'}{' '}
          </Button>
        )}
        {this.state.showModal ? (
          <div className="absolute top-0 left-0">
            <div
              className="flex justify-center items-center overflow-x-hidden 
              overflow-y-auto fixed inset-0 z-100 outline-none focus:outline-none 
              bg-blend-darken shadow-2xl bg-opacity-90 bg-gray-900"
              style={{ zIndex: 10000 }}
            >
              <div className="relative my-6 mx-auto w-2/3">
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t ">
                    <h3 className="text-xl font=semibold">{this.state.title}</h3>
                    <button
                      className="bg-gray-200 border-0 rounded-full float-right p-4 hover:pointer hover:bg-gray-400 "
                      onClick={() => this.hideModal()}
                    >
                      <ClearIcon />
                    </button>
                  </div>
                  <div className="relative p-6 flex-auto">{this.props.children}</div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    )
  }
}
