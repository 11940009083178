import { toast } from 'react-toastify'
import { searchBackendClient } from '../../../api/searchBackendClient'

export class WikimapiaAPIKey {
  private static readonly STORAGE_NAME = 'WIKIMAPIA_APIKEY'
  private readonly storage: Storage
  private _value: string | null = null

  constructor(storage?: Storage) {
    this.storage = storage ?? localStorage

    const storedKey = this.storage.getItem(WikimapiaAPIKey.STORAGE_NAME)

    if (storedKey) {
      this._value = storedKey.replace(/"/g, '')
    }
  }

  get value(): string | null {
    return this._value
  }

  get isValid(): boolean {
    return Boolean(this._value)
  }

  invalidate(): void {
    this._value = null
    this.storage.removeItem(WikimapiaAPIKey.STORAGE_NAME)
  }

  async refresh(): Promise<void> {
    toast.info('Attempt to refresh Wikimapia API key')

    try {
      const token = await searchBackendClient.post<string>('/v1/search/wikimapia/token')

      this.storage.setItem(WikimapiaAPIKey.STORAGE_NAME, token)
      this._value = token

      toast.success('Token refreshed successful')
    } catch (error) {
      toast.error(`Error while attempting to refresh Wikimapia's API key ${String(error)}`)

      throw error
    }
  }
}
