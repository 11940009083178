import SecurityMeasureNotification from '../SecurityMeasureNotification'
import type { PropsWithChildren } from 'react'

export type PublicIpGuardProps = PropsWithChildren<{
  isAllowedToProceed: boolean
}>

export default function PublicIpGuard({ children, isAllowedToProceed }: PublicIpGuardProps) {
  if (isAllowedToProceed) return <>{children}</>

  return <SecurityMeasureNotification />
}
