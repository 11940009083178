import React, { ReactNode } from 'react'
import { ALLOWED_PROVIDERS, MapItem, Marker, StoredData, VIEW_TYPE } from '../../MapsWrapper/types'
import MapsViewComponent from './MapsViewComponent'
import { DEFAULT_ZOOM, PROVIDERS, STORE_KEY, VIEW_TYPE_ONE_MAP } from '../constants'
import { MapFactory } from '../providers'
import '../styles.scss'
import 'leaflet'
import 'leaflet/dist/leaflet.css'
import { LatLng, Map } from 'leaflet'
import { createSetMainProviderBtn, isNewPosition, makeProviderContainerId } from '../utils'
import Utils from '../../common/Utils'
import 'leaflet-easybutton'
import 'leaflet-easybutton/src/easy-button.css'
import 'leaflet.sync'
import MapsNavigationComponent from './MapsNavigationComponent'

type MapsComponentProps = {
  providers: Array<ALLOWED_PROVIDERS>
  position: { lat: number; lng: number; zoom: number; desc?: string }
  goToPosition?: LatLng
  features: Array<any>
  currentPosition?: (position: LatLng, bbox: string) => any
  lastMarkedPosition?: (position: LatLng, zoom: number, desc?: string) => any
}
type MapsComponentState = {
  viewType: VIEW_TYPE
  sortedProviders: Array<ALLOWED_PROVIDERS>
  currentProvidersLayers: Partial<Record<ALLOWED_PROVIDERS, string>>
  zoom: number
  center: LatLng
  maps: Array<MapItem>
  markers: Array<Marker>
}

export default class MapsComponent extends React.Component<MapsComponentProps, MapsComponentState> {
  constructor(props: MapsComponentProps) {
    super(props)

    if (this.props.providers.length === 0) throw new Error('maps providers are empty')

    const {
      viewType = VIEW_TYPE_ONE_MAP,
      sortedProviders = PROVIDERS,
      currentProvidersLayers = {},
      // todo use feature only from Maps component. rm common Utils
    } = Utils.getObjectToLocalStorage(STORE_KEY) || ({} as StoredData)

    const { lat, lng, zoom, desc: title = 'Initial point' } = this.props.position

    this.state = {
      viewType,
      sortedProviders,
      currentProvidersLayers,
      maps: [],
      center: new LatLng(lat, lng),
      zoom,
      markers: [{ point: new LatLng(lat, lng), title }],
    }
  }

  componentDidMount = () => this.createMaps().catch((e) => console.log(e))

  componentDidUpdate(prevProps: Readonly<MapsComponentProps>) {
    if (prevProps.features.length < this.props.features.length)
      this.addFeatures(this.props.features.slice(prevProps.features.length > 0 ? prevProps.features.length - 1 : 0))

    if (
      this.props.goToPosition !== prevProps.goToPosition &&
      isNewPosition(this.state.center, this.props.goToPosition, 3)
    ) {
      const map = this.getMainMap() as Map
      map.setView(this.props.goToPosition as LatLng, 16)
    }
  }

  protected async createMaps(): Promise<void> {
    const maps: Array<MapItem> = []
    const { zoom, center, markers } = this.state

    await Promise.all(
      this.props.providers.map((provider, key) => {
        return new Promise((resolve) => {
          const options = {
            id: makeProviderContainerId(provider),
            provider,
            zoom,
            center,
            centerMapIcon: true,
            markers,
          }
          MapFactory.create(options)
            .then((map: Map) => {
              maps[key] = { id: options.id, provider, map }
              console.log('map created', options)
              resolve('done')
            })
            .catch((e) => {
              console.log('map create error', options, e)
              resolve(e)
            })
        })
      }),
    )
      .then(async () => {
        if (maps.length === 0) throw new Error('No map has been created')

        await new Promise((resolve) => this.setState({ maps }, () => resolve('Maps created')))
      })
      .then(() => {
        this.syncMaps()
        this.addEventsOnInit()
        this.addFeatures(this.props.features)
        this.state.maps.forEach(async ({ map, provider }) => {
          createSetMainProviderBtn(() => this.setMainProvider(provider)).addTo(map)
        })

        // init currentPosition before onmoved event happened
        this.props.currentPosition &&
          this.props.currentPosition(maps[0].map.getCenter(), maps[0].map.getBounds().toBBoxString())
      })
  }

  protected async syncMaps(): Promise<void> {
    this.state.maps.forEach((item, index) => {
      this.state.maps.forEach((iItem, iIndex) => index !== iIndex && item.map.sync(iItem.map))
    })
  }

  protected async unsyncMaps(): Promise<void> {
    this.state.maps.forEach((item, index) => {
      this.state.maps.forEach((iItem, iIndex) => index !== iIndex && item.map.unsync(iItem.map))
    })
  }

  public async setMainProvider(provider: ALLOWED_PROVIDERS): Promise<void> {
    const { sortedProviders } = this.state
    if (sortedProviders[0] === provider) return

    const newSorted = [provider]
    sortedProviders.forEach((item) => item !== provider && newSorted.push(item))

    this.setState({ sortedProviders: newSorted }, this.storeData)
  }

  public async changeViewType(event: any, viewType: VIEW_TYPE): Promise<void> {
    if (this.state.viewType === viewType || viewType === null) return

    this.setState({ viewType }, this.storeData)
  }

  protected async storeData(): Promise<void> {
    const { sortedProviders, currentProvidersLayers, viewType } = this.state
    // todo rm util dependency
    Utils.setObjectToLocalStorage(STORE_KEY, {
      sortedProviders,
      viewType,
      currentProvidersLayers,
    })
  }

  public async setNewPosition(event: any, position: LatLng, description?: string): Promise<void> {
    const {
      markers,
      maps,
      maps: [first],
    } = this.state
    const zoom = first.map.getZoom() < DEFAULT_ZOOM ? first.map.getZoom() : DEFAULT_ZOOM
    const marker = {
      point: position,
      title: description || `${position.lat} ${position.lng}`,
    }
    markers.push(marker)
    this.setState(
      { markers },
      () => this.props.lastMarkedPosition && this.props.lastMarkedPosition(position, zoom, marker.title),
    )
    maps.forEach((item) => MapFactory.addNewMarkerToMap(marker, item.map))

    await this.moveToPosition(position)
  }

  public async moveToLastPosition(): Promise<void> {
    const { markers } = this.state
    const last = markers.slice(-1)
    last[0] && (await this.moveToPosition(last[0].point))
  }

  protected async moveToPosition(position: LatLng): Promise<void> {
    const map = this.getMainMap()
    map && map.setView(position)
  }

  protected getMainMap(): Map | undefined {
    const { sortedProviders, maps } = this.state
    const sorted = sortedProviders.filter((provider) => this.props.providers.includes(provider))
    return maps.find(({ provider }) => provider === sorted[0])?.map
  }

  protected async addEventsOnInit(): Promise<void> {
    const main = this.getMainMap()

    main &&
      main.on('moveend', () => {
        this.setState({ center: main.getCenter(), zoom: main.getZoom() })
        this.props.currentPosition && this.props.currentPosition(main.getCenter(), main.getBounds().toBBoxString())
      })

    this.state.maps.forEach(({ map, provider }: MapItem) => {
      // Store selected layer for each map
      map.on('baselayerchange', async (e) => {
        const currentProvidersLayers = Object.assign({}, this.state.currentProvidersLayers, { [provider]: e.name })
        this.setState({ currentProvidersLayers }, this.storeData)
      })

      // Load tiles if map container was resized
      ;(async () => {
        const resizeObserver = new ResizeObserver(() => map.invalidateSize())
        resizeObserver.observe(map.getContainer())
      })()
    })
  }

  protected async addFeatures(features: any[]) {
    features.forEach((feature) => {
      this.state.maps.forEach(({ map }) => MapFactory.addFeatureToMap(map, feature))
    })
  }

  render(): ReactNode {
    const { viewType, sortedProviders, center, zoom } = this.state
    return (
      <div className="relative flex-1">
        <MapsNavigationComponent
          viewType={viewType}
          center={center}
          zoom={zoom}
          changeViewType={this.changeViewType.bind(this)}
          moveToPosition={this.setNewPosition.bind(this)}
          moveToLastPosition={this.moveToLastPosition.bind(this)}
        />
        <MapsViewComponent viewType={viewType} sorted={sortedProviders} {...this.props}></MapsViewComponent>
      </div>
    )
  }
}
