import { InputAdornment, TextField } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { Component, ReactNode } from 'react'
import { getSearchParameter, setSearchParameter } from '../../utils/searchParametersManager'
import Guide from './Guide'
import packageJSON from '../../../package.json'
import PublicIp from '../PublicIp'
import Loading from '../common/Loading'
import { NAME_MAX_LENGHT } from '../../utils/searchParametersValidation'
import { toast } from 'react-toastify'

type WelcomePageProps = any
type WelcomePageState = {
  showWelcomePage: boolean
  value: string
  isLoading: boolean
}

export default class WelcomePage extends Component<WelcomePageProps, WelcomePageState> {
  constructor(props: WelcomePageProps) {
    super(props)

    this.state = {
      showWelcomePage: !getSearchParameter('name') && !getSearchParameter('tab'),
      value: '',
      isLoading: true,
    }
  }

  onSubmit(value: string) {
    const errorMessages = setSearchParameter('name', value)
    if (errorMessages) {
      toast.warn(
        <>
          <p>Помилка пошукового запиту :</p>
          <ul>
            {errorMessages.map((msg, i) => (
              <li key={i}>{msg}</li>
            ))}
          </ul>
        </>,
      )
    }
  }

  renderInput() {
    return (
      <TextField
        fullWidth
        autoFocus
        label="Що шукаємо сьогодні ?"
        id="search"
        onChange={(e) => this.setState({ value: e.target.value.trim() })}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            event.preventDefault()
            if (!this.state.value) return
            this.onSubmit(this.state.value)
          }
        }}
        inputProps={{ max: NAME_MAX_LENGHT }}
        InputProps={{
          endAdornment: (
            <>
              <InputAdornment position="end">
                <div
                  title="Toggle hightlighting matches"
                  onClick={() => this.onSubmit(this.state.value)}
                  className={`rounded-full m-0 p-2 border-solid border-0 cursor-pointer`}
                >
                  <SearchIcon />
                </div>
              </InputAdornment>
            </>
          ),
        }}
      />
    )
  }

  renderMainContent() {
    return (
      <>
        <div className="w-full mt-20 flex-row">
          <h1 className="text-3xl w-full text-center">
            -- Shaffner <span className="text-gray-400 text-sm">v{packageJSON.version}</span> --
          </h1>
          <div className="my-8">{this.renderInput()}</div>
        </div>
        <div className="w-full h-96 px-16 flex-row">
          <Guide />
        </div>
      </>
    )
  }

  render(): ReactNode {
    if (!this.state.showWelcomePage) {
      return this.props.children
    }

    return (
      <div className="flex w-full h-full">
        <div className="basis-1/5">
          <div className="hidden">
            <PublicIp onSuccess={() => this.setState({ isLoading: false })} />
          </div>
        </div>
        <div className="basis-3/5">{this.state.isLoading ? <Loading /> : this.renderMainContent()}</div>
      </div>
    )
  }
}
