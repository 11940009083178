import { Control, LatLng } from 'leaflet'
import { ALLOWED_PROVIDERS } from '../../MapsWrapper/types'
import { DEFAULT_ZOOM, MAP_GOOGLE, MAP_OSM, MAP_WIKI, MAP_YANDEX } from '../constants'

export const createSetMainProviderBtn = (handler: () => void): Control.EasyButton => {
  return new Control.EasyButton({
    position: 'topleft',
    states: [
      {
        stateName: 'center',
        onClick: handler,
        title: 'Set map as the main',
        icon:
          '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-full h-full p-1">\n' +
          '  <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15" />\n' +
          '</svg>\n',
      },
    ],
  })
}

export const providerLinkByCoordinates = (
  provider: ALLOWED_PROVIDERS,
  coordinates: LatLng,
  zoom: number = DEFAULT_ZOOM,
): void => {
  const makeLink = (p: ALLOWED_PROVIDERS, c: LatLng, z: number): string => {
    switch (p) {
      case MAP_OSM:
        return `https://www.openstreetmap.org/query?lat=${c.lat}&lon=${c.lng}#map=${z}/${c.lat}/${c.lng}`
      case MAP_GOOGLE:
        return `https://www.google.com/maps/search/?api=1&query=${c.lat},${c.lng}`
      case MAP_YANDEX:
        return `https://yandex.ru/maps/?ll=${c.lng}%2C${c.lat}&mode=whatshere&whatshere%5Bpoint%5D=${coordinates.lng}%2C${coordinates.lat}&whatshere%5Bzoom%5D=${z}&z=${z}`
      case MAP_WIKI:
        return `https://wikimapia.org/#lang=ru&center.lat=${c.lat}&lon=${c.lng}&z=${z}&m=w`
    }
  }

  window.open(makeLink(provider, coordinates, zoom), '_blank')
}

export const makeProviderContainerId = (provider: ALLOWED_PROVIDERS): string => `map-provider-${provider}`

export const isNewPosition = (currentPosition: LatLng, newPosition: LatLng | undefined, dp: number = 3): boolean => {
  if (!newPosition) return false
  const n = newPosition as LatLng
  return currentPosition.lat.toFixed(dp) !== n.lat.toFixed(dp) || currentPosition.lng.toFixed(dp) !== n.lng.toFixed(dp)
}
