import { buildWebStorage } from 'axios-cache-interceptor'
import { createAxiosInstance } from './utils/axiosFactory'
import { useCorrelationID } from './interceptors/useCorrelationID'
import { useAuthorization } from './interceptors/useAuthorization'

const { REACT_APP_BACKEND_API_URL = 'http://localhost:4001', REACT_APP_CACHE_TTL } = process.env

export const backendClient = createAxiosInstance({
  baseURL: REACT_APP_BACKEND_API_URL,
  headers: {
    get: {
      Accept: 'application/json, text/plain, */*',
    },
  },
  ...(REACT_APP_CACHE_TTL && {
    cache: {
      interpretHeader: false,
      ttl: Number(REACT_APP_CACHE_TTL),
      storage: buildWebStorage(localStorage, 'backend-cache:'),
    },
  }),
  interceptors: [useCorrelationID, useAuthorization],
})
