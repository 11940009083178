import { toast } from 'react-toastify'

export class LoggerService {
  provider: string

  constructor(provider: string) {
    this.provider = provider
  }

  private renderTemplate(msg: string) {
    return `${this.provider} : ${msg}`
  }

  warn(message: string) {
    toast.warn(this.renderTemplate(message))
  }

  info(message: string) {
    toast.info(this.renderTemplate(message))
  }

  error(message: string) {
    toast.error(this.renderTemplate(message))
  }

  success(message: string) {
    toast.success(this.renderTemplate(message))
  }
}
