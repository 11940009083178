import { WikimapiaBoxItem, WikimapiaSearchItem, WikimapiaObjectDetail, WikimapiaNodeHistoryItem } from '../types'
import { toast } from 'react-toastify'
import { searchBackendClient } from '../../../api/searchBackendClient'
import { proxyClient } from '../../../api/proxyClient'
import { isObject } from '../../../utils/typeChecker'
import { WikimapiaAPIKey } from './WikimapiaAPIKey'

type WMRequest = {
  page: number
  count: number
}
type WMSearchRequest = WMRequest & {
  lat: number
  lon: number
  query: string
}
type WMBoxRequest = WMRequest & {
  category: number
  bbox: string
}
type WMResponse<T = unknown> = {
  count: number
  page: number
  found: number
  folder: T[]
}
type WMError = {
  debug: {
    code: number
    message: string
  }
}

export class WikimapiaAPI {
  private readonly apiKey: WikimapiaAPIKey
  private static API_URL = '/http://api.wikimapia.org'
  private static ACTION_SEARCH = 'search'
  private static ACTION_BOX = 'box'
  private static MAX_REQUEST_ATTEMPTS = 2

  constructor(apiKey: WikimapiaAPIKey) {
    this.apiKey = apiKey
  }

  public async getBox({ bbox, page, count, category }: WMBoxRequest): Promise<WMResponse<WikimapiaBoxItem>> {
    const url = `${WikimapiaAPI.API_URL}/?function=${WikimapiaAPI.ACTION_BOX}&lang=ru&format=json&bbox=${bbox}&page=${page}&count=${count}&category=${category}`
    return this.request<WMResponse<WikimapiaBoxItem>>(url)
  }

  public async search({ query, lat, lon, page, count }: WMSearchRequest): Promise<WMResponse<WikimapiaSearchItem>> {
    const url = `${WikimapiaAPI.API_URL}/?function=${WikimapiaAPI.ACTION_SEARCH}&lang=ru&format=json&page=${page}&q=${query}&lat=${lat.toFixed(
      3,
    )}&lon=${lon.toFixed(3)}&count=${count}`
    return await this.request<WMResponse<WikimapiaSearchItem>>(url)
  }

  public async getObjectById(id: number): Promise<WikimapiaObjectDetail> {
    const url = `${WikimapiaAPI.API_URL}/?function=place.getbyid&id=${id}&format=json&language=ru`
    return await this.request<WikimapiaObjectDetail>(url)
  }

  private async request<T extends object>(url: string): Promise<T> {
    let retry = false
    let attempt = 0
    let triedRefresh = false

    do {
      if (!this.apiKey.isValid) {
        await this.apiKey.refresh()

        triedRefresh = true
      }

      try {
        attempt++
        attempt > 1 && toast.info(`Retry request. Attempt ${attempt}.`)

        const response = await proxyClient.get<T | WMError>(`${url}&key=${this.apiKey.value}`)

        if (!WikimapiaAPI.isWMError(response)) {
          return response
        }

        toast.info(`Wikimapia: ${response.debug.message}`)

        // token is not valid, need to refresh
        if (response.debug.message === 'Key limit has been reached' || response.debug.message === 'Invalid key') {
          this.apiKey.invalidate()

          retry = true
        }
      } catch (e) {
        toast.error(`Wikimapia : ${String(e)}`)
      }
    } while (retry && attempt <= WikimapiaAPI.MAX_REQUEST_ATTEMPTS && !triedRefresh)

    toast.warn(`Empty response after ${attempt} attempt(s)`)
    throw Error(`Empty response after ${attempt} attempt(s)`)
  }

  public static history(nodeId: number): Promise<WikimapiaNodeHistoryItem[]> {
    return searchBackendClient.get<WikimapiaNodeHistoryItem[]>(`v1/search/wikimapia/history/${nodeId}`)
  }

  private static isWMError(response: unknown): response is WMError {
    return isObject(response) && 'debug' in response
  }
}
