export const MILITARY_UNIT_NAME_REGEXP = new RegExp(
  /* eslint-disable-next-line */
  '(во[а-я]*\\s+част[а-я]*(\\s+№)?|ВОЙСК[A-Я]*\\s+ЧАСТ[A-Я]*(\\s+№)?|[Вв]\\/[Чч]|[Вв]W?[Чч])\\s{0,2}(\\d{4,5}(\\s{0,3}[-–]?\\s{0,3}("?[а-яА-Яa-zA-Z\\d]{1,2}"?|[а-яА-Яa-zA-Z\\d]{0,2}))?)',
  'gim',
)

export const SITES = [
  {
    title: 'Telegram',
    description: 'Пошук результатів на Telegram',
    filter: 'site:t.me',
  },
  {
    title: 'Wiki',
    description: 'Пошук результатів на Wikipedia',
    filter: 'site:wikipedia.org',
  },
  {
    title: 'LJ',
    description: 'Пошук результатів на LiveJournal',
    filter: 'site:livejournal.com',
  },
  {
    title: 'VK',
    description: 'Пошук результатів на Vkontakte',
    filter: 'site:vk.com',
  },
  {
    title: 'OK',
    description: 'Пошук результатів на Odnoklasniki',
    filter: 'site:ok.ru',
  },
  {
    title: 'WM',
    description: 'Пошук результатів на Wikimapia',
    filter: 'site:wikimapia.org',
  },
  {
    title: 'OSM',
    description: 'Пошук результатів на OpenStreetMap',
    filter: 'site:openstreetmap.org',
  },
  {
    title: 'TikTok',
    description: 'Пошук результатів на Tiktok',
    filter: 'site:tiktok.com',
  },
  {
    title: 'Insta',
    description: 'Пошук результатів на Instagram',
    filter: 'site:instagram.com',
  },
  {
    title: 'X',
    description: 'Пошук результатів на Twitter',
    filter: 'site:twitter.com',
  },
  {
    title: 'YouTube',
    description: 'Пошук результатів на Youtube',
    filter: 'site:youtube.com',
  },
  {
    title: 'RuTube',
    description: 'Пошук результатів на Rutube',
    filter: 'site:rutube.ru',
  },
  // { title: 'Dzen', description: 'Пошук результатів на Dzen', filter: 'site:youtube.com' },
  { title: 'XLS', description: 'Пошук файлів Excel', filter: 'filetype:xls' },
  // { title: 'DOC', description: 'Search Word files', filter: 'filetype:doc' },
  { title: 'PDF', description: 'Пошук файлів PDF', filter: 'filetype:pdf' },
]
