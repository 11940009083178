import React from 'react'
import type { ALLOWED_PROVIDERS, VIEW_TYPE } from '../../MapsWrapper/types'
import { makeProviderContainerId } from '../utils'
import { VIEW_TYPE_FOUR_TILES, VIEW_TYPE_MAIN_WITH_MINI, VIEW_TYPE_ONE_MAP } from '../constants'

const MAX_CONTAINERS_COUNT = 4

type MapsViewComponentProps = {
  viewType: VIEW_TYPE
  providers: Array<ALLOWED_PROVIDERS>
  sorted: Array<ALLOWED_PROVIDERS>
}

const classesByIndex = (index: number, count: number, viewType: VIEW_TYPE): string => {
  const classes = [`map-container-number-${index}`]

  if (viewType === VIEW_TYPE_ONE_MAP) {
    switch (index) {
      case 1:
        classes.push('w-full', 'h-full', 'relative')
        break
      case 2:
      case 3:
      case 4:
      default:
        classes.push('hidden')
    }
  } else if (viewType === VIEW_TYPE_FOUR_TILES) {
    switch (count) {
      case 1:
        classes.push('w-full', 'h-full')
        break
      case 2:
        if (index === 1) {
          classes.push('w-1/2', 'h-full', 'rounded-sm', 'border')
        } else {
          classes.push('w-1/2', 'h-full', 'rounded-sm', 'border')
        }
        break
      case 3:
        if (index === 1) {
          classes.push('w-full', 'h-2/3', `order-${index - 1}`, 'rounded-sm', 'border')
        } else {
          classes.push('w-1/2', 'h-1/3', `order-${index - 1}`, 'rounded-sm', 'border')
        }
        break
      case 4:
        if (index === 1 || index === 2) {
          classes.push('w-1/2', 'h-3/5', `order-${index}`, 'rounded-sm', 'border')
        } else {
          classes.push('w-1/2', 'h-2/5', `order-${index}`, 'rounded-sm', 'border')
        }
        break
    }
  } else if (viewType === VIEW_TYPE_MAIN_WITH_MINI) {
    switch (count) {
      case 1:
        classes.push('w-full', 'h-full', 'relative', 'z-10')
        break
      case 2:
        if (index === 1) {
          classes.push('w-full', 'h-full', 'z-10')
        } else {
          classes.push('absolute', 'w-1/4', 'h-1/3', 'bottom-0', 'right-0', 'rounded-sm', 'border', 'z-20')
        }
        break
      case 3:
        if (index === 1) {
          classes.push('w-full', 'h-full', 'relative', 'z-10')
        } else if (index === 2) {
          classes.push('absolute', 'w-1/4', 'h-1/3', 'bottom-0', 'right-0', 'rounded-sm', 'border', 'z-20')
        } else {
          classes.push('absolute', 'w-1/4', 'h-1/3', 'bottom-1/3', 'right-0', 'rounded-sm', 'border', 'z-20')
        }
        break
      case 4:
        if (index === 1) {
          classes.push('w-full', 'h-full', 'relative', 'z-10')
        } else if (index === 2) {
          classes.push(
            'absolute',
            'w-1/4',
            'h-[calc((100%-4rem)/3)]',
            'bottom-0',
            'right-0',
            'rounded-sm',
            'border',
            'z-20',
          )
        } else if (index === 3) {
          classes.push(
            'absolute',
            'w-1/4',
            'h-[calc((100%-4rem)/3)]',
            'bottom-[calc((100%-4rem)/3)]',
            'right-0',
            'rounded-sm',
            'border',
            'z-20',
          )
        } else {
          classes.push(
            'absolute',
            'w-1/4',
            'h-[calc((100%_-_4rem)/3)]',
            'bottom-[calc(2*(100%-4rem)/3)]',
            'right-0',
            'rounded-sm',
            'border',
            'z-20',
          )
        }
    }
  }

  return classes.join(' ')
}

const classByViewType = (viewType: VIEW_TYPE): string => {
  return `maps-${viewType}`
}

export default function MapsViewComponent({ viewType, providers, sorted }: MapsViewComponentProps) {
  if (providers.length > MAX_CONTAINERS_COUNT) providers = providers.slice(0, MAX_CONTAINERS_COUNT)

  const count = providers.length
  sorted = sorted.filter((provider) => providers.includes(provider))

  return (
    <div className={'w-full h-full relative flex flex-row flex-wrap ' + classByViewType(viewType)}>
      {providers.map((provider: ALLOWED_PROVIDERS) => {
        const index = sorted.indexOf(provider) + 1
        return (
          <div key={'map-' + provider} className={classesByIndex(index, count, viewType)}>
            <div id={makeProviderContainerId(provider)} className="w-full h-full"></div>
          </div>
        )
      })}
    </div>
  )
}
