import AnnouncementIcon from '@mui/icons-material/Announcement'

export default function SecurityMeasureNotification() {
  return (
    <div className="mt-96 text-center text-2xl text-gray-400">
      <p>
        Будьте терплячі, ми перевіряємо анонімність з&apos;єднання.
        <br />
        Також переконайтесь чи увімкнуте VPN з&apos;єднання на Вашому комп&apos;ютері.
      </p>
      <AnnouncementIcon />
    </div>
  )
}
