import { isObject } from './typeChecker'

export function deepFreeze<T extends object>(obj: T): Readonly<T> {
  if (!isObject(obj)) {
    return obj
  }

  const propNames = Object.getOwnPropertyNames(obj)

  for (const name of propNames) {
    const value = (obj as Record<string, unknown>)[name]
    if (isObject(value)) {
      deepFreeze(value)
    }
  }

  return Object.freeze(obj)
}
