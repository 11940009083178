// Initialization for ES Users
import React, { Component } from 'react'
import MUIButton from '@mui/material/Button'
import ClearIcon from '@mui/icons-material/Clear'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import TuneIcon from '@mui/icons-material/Tune'
import PreviewIcon from '@mui/icons-material/Preview'
import BoltIcon from '@mui/icons-material/Bolt'
import CachedIcon from '@mui/icons-material/Cached'
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff'
import StarsIcon from '@mui/icons-material/Star'
import NumberInput from '../common/NumberInput'
import Modal from '../common/Modal'
import Button from '../common/Button'
import Utils from '../common/Utils'
import Loading from '../common/Loading'
import { toast } from 'react-toastify'
import { UnsafeResourceLink } from '../common/UnsafeResourceLink'
import { ModalPreviewPage } from './ModalPreviewPage'
import './style.css'
import { Checkbox, FormControlLabel, InputAdornment, TextField } from '@mui/material'
import { CustomWidthTooltip } from '../common/Tooltip'
import { MILITARY_UNIT_NAME_REGEXP, SITES } from './constants'
import { searchBackendClient } from '../../api/searchBackendClient'

//TODO: fix typo hightlight -> highlight 
/* eslint-disable  no-unused-vars */
export type SearchResult = {
  title: string
  link: string
  snippet: string
  image: string
  relevanceMark?: number
  cacheLink?: string
  highlighedTitle?: Element
  highlighedSnippet?: Element
}
export type SearchListProps = {
  name: string
  site?: string
  filter?: string
  onItemClick?: (item: SearchResult, site?: string) => any
}
export type SearchListState = {
  searchQuery: string
  searchResults: Map<string, SearchResult[]>
  filteredSearchResults: SearchResult[]
  iframe: any
  showModal: boolean
  isLoading: boolean
  filter?: string
  filterEnabled?: boolean
  enableStrictMatchFinter?: boolean
  enableHightlight: boolean
  enableMilitaryUnitNumberHightlight: boolean
  domainFilter: Set<string>
  lastPageNumber: number
  pageInProgress?: number | null
  site?: string
  matchesMilUnitPattern: boolean
  searchResultCounters: Map<string, { found: number; filtered: number }>
  sitesEnabled: Set<string>
  allSitesEnabled: boolean
  enableStrictOrderMatch: boolean
  relevanceFilterLimit: number
}
export type SearchQueryOptions = {
  query: string
  site?: string
  exclude?: string
}
export type SearchResponseItem = {
  value: { results: SearchResult[]; site: string }
  status: string
  reason: {
    response: {
      message: string
    }
  }
}
/* eslint-enable  no-unused-vars */

// TODO Take a look at https://hackernoon.com/scraping-google-search-results-with-node-js
// idea : also we can consider "nightmare" as parser lib
export default class SearchList extends Component<SearchListProps, SearchListState> {
  public static LOCAL_STORAGE_FILTER = 'common_search_black_list'
  public static LOCAL_STORAGE_SITES = 'common_sites_enabled'

  protected delay = 3000 // ms
  protected pageLimit = 1 // loads pages automatilacally
  protected limitEnabledSites = 9
  public modal: any
  private cache: Map<string, any>

  constructor(props: SearchListProps) {
    super(props)

    this.cache = new Map()

    this.state = {
      searchQuery: (this.props.name || '').trim(),
      searchResults: new Map(),
      filteredSearchResults: [],
      iframe: <Loading />,
      showModal: false,
      isLoading: true,
      filter: this.props.filter || this.makeFilterKeyWords(),
      domainFilter: new Set(),
      lastPageNumber: 0,
      site: props.site,
      matchesMilUnitPattern: MILITARY_UNIT_NAME_REGEXP.test(this.props.name),
      searchResultCounters: new Map(),
      sitesEnabled: new Set(SITES.map(({ title }) => title)),
      allSitesEnabled: true,
      enableHightlight: true,
      filterEnabled: true,
      enableStrictMatchFinter: false,
      enableMilitaryUnitNumberHightlight: localStorage.getItem('enableMilitaryUnitNumberHightlight')
        ? localStorage.getItem('enableMilitaryUnitNumberHightlight') === 'true'
        : true,
      enableStrictOrderMatch: localStorage.getItem('enableStrictOrderMatch')
        ? localStorage.getItem('enableStrictOrderMatch') === 'true'
        : true,
      relevanceFilterLimit: Number(
        localStorage.getItem('relevanceFilterLimit') ? localStorage.getItem('relevanceFilterLimit') : 60,
      ),
    }

    this.modal = React.createRef()
  }

  /**
   * componentDidMount
   *
   * @returns
   */
  async componentDidMount(): Promise<void> {
    await this.loadDataFromLocalStorage(
      'domainFilter',
      SearchList.LOCAL_STORAGE_FILTER,
      (data: string[]) => new Set(data),
    )
    await this.loadDataFromLocalStorage(
      'sitesEnabled',
      SearchList.LOCAL_STORAGE_SITES,
      (data: string[]) => new Set(data),
    )

    await this.setState({
      searchQuery: this.createQuery(),
    })

    this.setState({
      allSitesEnabled: SITES.every(({ title }) => this.state.sitesEnabled.has(title)),
    })

    await this.runParser()
  }

  getSiteFilter() {
    return this.state.site || 'default'
  }

  makeFilterKeyWords() {
    return (
      this.props.name
        .trim()
        /* eslint-disable-next-line no-useless-escape */
        .replace(/^["\(\[\{]?(.*)["\)\]\}]$/, `$1`) // "something" => something, (something) => something
        .replaceAll('  ', ' ')
        .replaceAll('-я', '')
        .replaceAll('-aя', '')
        .replaceAll('-й', '')
        .replaceAll(/[.*+?^${}()|[\]\\]/g, '')
        .split(' ')
        .map((word: string) => {
          if (/^(\d+)/.test(word)) {
            return word?.match(/^(\d+)/)?.shift()
          }
          return word
        })
        .filter((v: string | undefined) => v && (+v > 0 || v.length > 2))
        .join(' ')
    )
  }

  /**
   * Creates google search url with params
   * Anatomy of url look here https://gist.github.com/sshay77/4b1f6616a7afabc1ce2a
   * And here
   * https://aicontentfy.com/en/blog/demystifying-google-search-url-parameters-and-how-to-use-them
   *
   * @param page
   * @returns
   */
  getUrlOptions(page: number) {
    let url = `/v1/search/?query=${encodeURI(this.state.searchQuery)}`
    if (this.state.site) {
      /**
       * Set specific site for search
       */
      url += `&site=${encodeURI(this.state.site)}`
    } else if (this.state.domainFilter.size) {
      /**
       * Add exclude options
       */
      const exclude = Array.from(this.state.domainFilter).join(';')
      url += `&exclude=${exclude}`
    }
    return {
      url: page > 0 ? `${url}&start=${page * 10}` : url,
      headers: {},
    }
  }

  checkForCaptcha(response: string) {
    return response.indexOf('recaptcha') !== -1
  }

  protected createQuery(site?: string | undefined): string {
    let query = this.props.name?.trim()
    let unitNumber = Utils.getMilUnitNumber(query)

    if (unitNumber.indexOf('"') !== 0) {
      /**
       * ВОЙСКОВАЯ ЧАСТЬ 39030-"10" -> ВОЙСКОВАЯ ЧАСТЬ 39030-10
       */
      const newUnitNumber = unitNumber.replaceAll('"', '')
      query = query.replaceAll(unitNumber, newUnitNumber)
      unitNumber = newUnitNumber
    }

    const parts = []
    if (site) {
      unitNumber ? parts.push(`в ч ${unitNumber}`) : parts.push(`${query}`)
    } else {
      parts.push(query)
    }

    return parts.join(' ')
  }

  /**
   * loadDataFromLocalStorage
   *
   * @returns
   */
  protected async loadDataFromLocalStorage(stateKey: string, storageKey: string, fn?: any) {
    return new Promise((res) => {
      try {
        const data = Utils.getObjectToLocalStorage(storageKey)

        if (!data) return res(null)

        console.log(`Loaded ${stateKey} from ${storageKey}`, data)
        this.setState({ [stateKey]: fn ? fn(data) : data } as any, () => res(data))
      } catch (err) {
        console.error(err)
      }
    })
  }

  protected async runParser(pageFrom = 0) {
    if (!this.state.searchQuery) {
      console.log('Search query is empty')
      return
    }

    await this.setState(() => ({ isLoading: true, pageInProgress: pageFrom }))

    if (pageFrom === 0) {
      this.setState(() => ({
        searchResults: new Map(),
        filteredSearchResults: [],
      }))
    }

    let page = pageFrom
    do {
      try {
        const isOk = await this.loadAndSetState(page)
        isOk && ++page
        await new Promise((res) => setTimeout(res, this.delay))
      } catch (error) {
        console.error('Error while fetching data:', error)
        break
      }
    } while (page < this.pageLimit)

    await this.setState(() => ({ isLoading: false, pageInProgress: null }))
  }

  /**
   * loadAndSetState
   *
   * @param i
   * @returns
   */
  private async loadAndSetState(page: number = 0): Promise<boolean> {
    if (!this.state.searchQuery) {
      this.setState({ isLoading: false })
      return false
    }

    /**
     * Loads data from discovery server.
     * Uses retry if got captcha.
     */
    const source = 'google'
    const url = `/v1/search?source=${source}`
    const reqOptions = {
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Accept-Encoding': 'gzip, compress, deflate, br',
        traceId: `trace-id-${Math.round(Math.random() * 10000)}`,
      },
    }

    const body: SearchQueryOptions[] = SITES.filter(({ title }) => this.state.sitesEnabled.has(title)).map(
      ({ filter }) => ({
        query: this.createQuery(filter),
        site: filter,
      }),
    )

    body.unshift({
      query: this.createQuery(),
      exclude: Array.from(this.state.domainFilter).join(';'),
    })

    let needRetry = false
    let attempts = 1
    let response: SearchResponseItem[]
    const attemptsLimit = +(process.env.REACT_APP_REQUEST_ATTEMPT_LIMIT || 3)
    let errors
    do {
      errors = null

      try {
        response = await searchBackendClient.post<SearchResponseItem[]>(url, body, reqOptions)

        errors = Array.isArray(response) ? await this.handleResponse(response, page) : new Set(['Empty response'])

        needRetry = errors?.size > 0

        if (needRetry || !response) {
          toast.warn(
            <div>
              <p>Деякі запити повернули помилки</p>
              <ul className="text-sm">
                {Array.from(errors).map((m, i) => (
                  <li key={i}> - {m}</li>
                ))}
              </ul>
              <p>
                Повторна спроба {attempts}/{attemptsLimit}
              </p>
            </div>,
          )
        }
      } catch (err: any) {
        toast.error(
          err?.response?.data?.message || (
            <div>
              <p>Помилка сервера</p>
              <small> {err?.response?.data?.message} </small>
              <hr />
              <small>Спробуйте перезавантажити сторінку трішки пізніше</small>
              <MUIButton variant="outlined" color="success" onClick={this.onClickRunOrPause.bind(this)}>
                Перезавантажити <CachedIcon />
              </MUIButton>
            </div>
          ),
        )
        throw err
      }

      console.log({ attempts, attemptsLimit })
    } while ((!response || needRetry) && attempts++ < attemptsLimit)

    if (attempts > attemptsLimit) {
      toast.warn(`Спроби вичерпано. Спробуйте перезавантажити сторінки трішки пізніше.`)
    }

    return true
  }

  handleResponse(items: SearchResponseItem[], page = 1): Promise<Set<string>> {
    const errorMessages = new Set<string>()
    return new Promise((res) =>
      this.setState(
        (prevState) => ({
          searchResults: items.reduce((acc: Map<string, SearchResult[]>, item: SearchResponseItem) => {
            if (item.status === 'rejected') {
              if (item?.reason?.response?.message) {
                errorMessages.add(item?.reason?.response?.message)
              }
              return acc
            }

            if (!item.value) return acc

            acc.set(
              item.value.site || 'default',
              Array.from(
                item.value.results
                  .reduce((acc: Map<string, SearchResult>, item: SearchResult) => {
                    if (item?.link && !acc.has(item?.link)) acc.set(item?.link, item)
                    return acc
                  }, new Map())
                  .values(),
              ),
            )
            return acc
          }, prevState.searchResults),
          lastPageNumber: page,
        }),
        () => {
          res(errorMessages)
          return this.doFilter()
        },
      ),
    )
  }

  /**
   * onFilter
   * @param filter
   */
  onFilter(filter?: string) {
    if (filter || filter === '') {
      this.setState({ filter }, this.doFilter)
    } else {
      const copyFilter = this.state.filter
      this.setState(
        { filter, filterEnabled: !this.state.filterEnabled },
        () => (
          this.doFilter(),
          this.setState({
            filter: copyFilter,
            filterEnabled: this.state.filterEnabled,
          })
        ),
      )
    }
  }

  async countResults() {
    const preparedData = await Promise.all(
      Array.from(this.state.searchResults.keys()).map((siteFilterKey: string) => {
        const results = this.state.searchResults.get(siteFilterKey) || []
        return this.filterSearchResults(results).then((filtered) => [
          siteFilterKey,
          { found: results.length, filtered: filtered.length },
        ])
      }),
    )

    this.setState({
      searchResultCounters: new Map(preparedData as any),
    })
  }

  async filterSearchResults(results: SearchResult[]): Promise<SearchResult[]> {
    let filteredSearchResults = results

    const filter = this.state.filter
    const domainFilter = this.state.domainFilter

    if (domainFilter.size) {
      const domainFilterArray = Array.from(domainFilter)
      filteredSearchResults = filteredSearchResults.filter(({ link }: SearchResult) =>
        domainFilterArray.every((domain) => `${link}`.indexOf(domain) === -1),
      )
    }

    if (filter) {
      const filterParts = filter.split(';')
      const filters = filterParts.map((filter) => this.prepareFilterToEstimateMarkOfRelevance(filter))

      filteredSearchResults = await Promise.all(
        filteredSearchResults.map(async (filteredSearchResult) => {
          const relevanceMarks = await Promise.all(
            filters.map((filter) =>
              this.matchFilterEstimation(
                filter, // string[]
                `${filteredSearchResult.snippet} ${filteredSearchResult.title}`,
              ),
            ),
          )
          filteredSearchResult.relevanceMark = Math.max(...relevanceMarks) || 0

          filteredSearchResult.highlighedTitle = await this.hightlightText(filteredSearchResult.title)
          filteredSearchResult.highlighedSnippet = await this.hightlightText(filteredSearchResult.snippet)

          return filteredSearchResult
        }),
      )

      if (this.state.filterEnabled) {
        filteredSearchResults = filteredSearchResults
          .filter(({ title, snippet, link, relevanceMark }) =>
            this.state.enableStrictMatchFinter
              ? filterParts.some((fltr) => `${title} ${snippet} ${link}`.indexOf(fltr) !== -1)
              : relevanceMark && relevanceMark > this.state.relevanceFilterLimit,
          )
          .sort((a: any, b: any) => {
            if (a.relevanceMark === b.relevanceMark) return 0

            return a.relevanceMark > b.relevanceMark ? -1 : 1
          })
      }
    }

    return filteredSearchResults
  }

  /**
   * doFilter
   */
  async doFilter() {
    const filteredSearchResults: SearchResult[] =
      this.state.searchResults.get(this.getSiteFilter()) || ([] as SearchResult[])
    this.setState({
      filteredSearchResults: await this.filterSearchResults(filteredSearchResults),
    })
    return this.countResults()
  }

  /**
   * addDomainFilter
   *
   * @param item
   * @returns
   */
  addDomainFilter(link: string) {
    if (!link) {
      console.error('The link is empty')
      return
    }

    const url = new URL(link)
    const domain = url.hostname.replace('www.', '')

    if (!window.confirm(`Are you sure, you don't want to see any results from "${domain}" anymore ?`)) {
      console.log('Skipped adding domain to blacklist')
      return
    }

    if (!this.state.domainFilter.has(domain)) {
      const domainFilter = this.state.domainFilter.add(domain)
      Utils.setObjectToLocalStorage(SearchList.LOCAL_STORAGE_FILTER, Array.from(domainFilter))

      toast.success(
        <div>
          <p>
            The domain <b>{domain}</b> has been added to blacklist.
          </p>
          <p>You can revert that in prefferences anytime you want.</p>
        </div>,
      )

      return this.doFilter()
    }
  }

  /**
   * removeDomainFilter
   *
   * @param domain
   */
  removeDomainFilter(domain: string) {
    const domainFilter = this.state.domainFilter
    domainFilter.delete(domain)

    this.setState({ domainFilter })

    Utils.setObjectToLocalStorage(SearchList.LOCAL_STORAGE_FILTER, Array.from(domainFilter))

    return this.doFilter()
  }

  /**
   * onClickRunOrPause
   */
  onClickRunOrPause() {
    if (!this.state.isLoading) {
      this.setState({ searchResults: new Map(), filteredSearchResults: [] }, () => this.runParser())
    }
  }

  /**
   * onClickLoadMore
   *
   * @returns
   */
  async onClickLoadMore() {
    await this.setState({ isLoading: true })
    return this.runParser(this.state.lastPageNumber + 1)
  }

  hightlightText(text: string): Promise<Element> {
    return new Promise((res) => {
      // aims to avoid highlight collision
      const emphasiserQuoteStart = 'IGNORE_START'
      const emphasiserQuoteEnd = 'IGNORE_END'

      if (this.state.enableMilitaryUnitNumberHightlight) {
        text = text.replaceAll(MILITARY_UNIT_NAME_REGEXP, `<a ${emphasiserQuoteStart}$4${emphasiserQuoteEnd}>$1 $4</a>`)
      }

      const filter = this.state.filter?.trim()
      if (filter && this.state.enableHightlight) {
        if (this.state.enableStrictMatchFinter) {
          /**
           * Strict match
           */
          text = filter.split(';').reduce((acc: string, word: string) => {
            if (!word.trim()) return acc
            return acc.replaceAll(
              new RegExp(`(?<!${emphasiserQuoteStart})${word}(?!${emphasiserQuoteEnd})`, 'ig'),
              `<matches>${word}</matches>`,
            )
          }, `<div>${text}</div>`) as string
        } else {
          if (!this.cache.has(filter)) {
            this.cache.set(
              filter,
              Array.from(
                new Set(
                  filter
                    .replaceAll(';', ' ')
                    .replaceAll(/[-".*+?^${}()|[\]\\]/g, '\\$&')
                    .split(' ')
                    .map((word) => word.trim()),
                ),
              ),
            )
          }

          /**
           * Aproximate matches
           */
          const processedWords = new Set()
          text = this.cache.get(filter).reduce((acc: string, word: string) => {
            if (!word || processedWords.has(word)) return acc

            processedWords.add(word)
            return acc.replaceAll(
              new RegExp(`(?<!${emphasiserQuoteStart})${word}(?!${emphasiserQuoteEnd})`, 'ig'),
              `<highlight>${word.replaceAll('\\', '')}</highlight>`,
            )
          }, `<div>${text}</div>`) as string
        }
        text = text
          .replaceAll(
            emphasiserQuoteStart,
            `class="highlighMilUnitNumber" target="_blank" href="${location.pathname}?name=ВОЙСКОВАЯ ЧАСТЬ `,
          )
          .replaceAll(emphasiserQuoteEnd, '"')
      }

      res((<div dangerouslySetInnerHTML={{ __html: text }} />) as any)
    })
  }

  prepareFilterToEstimateMarkOfRelevance(filter: string): string[] {
    filter = filter?.trim()
    if (!filter) {
      return []
    }
    const words = filter
      .split(' ')
      .reduce((acc, word) => {
        word = word
          .replaceAll(/[.*+?^${}()|[\]\\]/g, '\\$&')
          .trim()
          .toLowerCase()
        word &&
          acc.add(
            /* trim end of words */
            Number.isNaN(+word) && word.length > 7 ? word.substring(0, word.length - 2) : word,
          )
        return acc
      }, new Set())
      .values()

    return Array.from(words) as string[]
  }

  /**
   * Estimate mark of relevance
   *
   * @param filter
   * @param text
   * @returns
   */
  matchFilterEstimation(words: string[], text: string): Promise<number> {
    return new Promise((res) => {
      if (this.state.enableStrictOrderMatch) {
        /**
         * Takes into account the order of words in text and filter
         */
        const pool = Array.from(words)
        do {
          const r = new RegExp(pool.join('.{0,20}'), 'ig')
          if (r.test(text)) {
            return res(Math.round((pool.length / words.length) * 100))
          }
          pool.pop()
        } while (pool.length)

        return res(0)
      } else {
        /**
         * Just counts matched words
         */
        const count = words.reduce((acc, word) => (new RegExp(`${word}`, 'i').test(text) ? acc + 1 : acc), 0)

        return res(Math.round((count / words.length) * 100))
      }
    })
  }

  /**
   * runSpecificSearch
   *
   * @param site
   * @returns
   */
  async runSpecificSearch(site?: string) {
    await this.setState({
      site,
    })
    // return this.runParser()
    this.doFilter()
  }

  isActiveSiteButton(site?: string) {
    return this.state.site === site || (!site && !this.state.site) ? 'bg-gray-400 text-white hover:bg-gray-400' : ''
  }

  /**
   * Set site enabled/disabled
   *
   * @param value
   * @param enable
   */
  toggleSite(value: string, enable?: boolean) {
    const sitesEnabled = this.state.sitesEnabled
    switch (true) {
      case enable === false:
        sitesEnabled.delete(value)
        break
      case enable === true:
        sitesEnabled.add(value)
        break
      case sitesEnabled.has(value):
        sitesEnabled.delete(value)
        break
      case !sitesEnabled.has(value):
        sitesEnabled.add(value)
        break
    }

    this.setState({ sitesEnabled })

    Utils.setObjectToLocalStorage(SearchList.LOCAL_STORAGE_SITES, Array.from(this.state.sitesEnabled))
  }

  togglePrefferencesInLocalStorage(key: keyof SearchListState) {
    const value = !(this.state[key] as boolean)
    this.setState({ [key]: value } as any as SearchListState, () => localStorage.setItem(key, `${value}`))
  }

  renderSiteButtonCounter(siteFilter: string) {
    if (!this.state.searchResultCounters.has(siteFilter)) {
      return ''
    }

    const { found, filtered } = this.state.searchResultCounters.get(siteFilter) as any
    const color = found ? (filtered ? 'bg-green-300' : 'bg-yellow-300') : 'bg-red-300'

    return (
      <div className={`rounded-full px-2 py-1 absolute z-[99] -mt-2 text-xs ${color}`}>
        {filtered}/{found}
      </div>
    )
  }

  /**
   * renderSiteButtons
   *
   * @returns
   */
  renderSiteButtons() {
    return (
      <div>
        <div className="px-1 inline-block">
          <div className="float-left">
            {this.renderSiteButtonCounter('default')}
            <Button
              title="Змішані результати"
              onClick={() => this.runSpecificSearch('default')}
              className={this.isActiveSiteButton()}
            >
              <FilterAltOffIcon />
            </Button>
          </div>
        </div>
        <div className="px-1 inline-block">
          {SITES.map(({ title, filter, description }) =>
            this.state.sitesEnabled.has(title) ? (
              <div className="float-left" key={filter}>
                {this.renderSiteButtonCounter(filter)}
                <Button
                  key={filter}
                  title={description}
                  onClick={() => this.runSpecificSearch(filter)}
                  className={this.isActiveSiteButton(filter)}
                >
                  {title}
                </Button>
              </div>
            ) : (
              ''
            ),
          )}
        </div>
      </div>
    )
  }

  renderPreferences() {
    return (
      <Modal
        title={`Налаштування пошуковика`}
        buttonText={<TuneIcon />}
        buttonCss={`p-4 border-0 active:bg-gray-400 bg-gray-100 hover:bg-gray-200`}
      >
        <div className="flex w-full">
          <div className="flex flex-row w-full">
            <div className="basis-2/3">
              <div className="min-h-36 w-full">
                <h4 className="text-2xl border-b border-gray-200 pb-2">Фільтр</h4>
                <ul>
                  <li>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.enableMilitaryUnitNumberHightlight}
                          onChange={() => this.togglePrefferencesInLocalStorage('enableMilitaryUnitNumberHightlight')}
                        />
                      }
                      label={`Підсвітка будь-яких знайдених номерів військових частин (приклад "в/ч 1234")`}
                    />
                    <li>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.state.enableStrictOrderMatch}
                            onChange={() => {
                              this.togglePrefferencesInLocalStorage('enableStrictOrderMatch')
                              return this.doFilter()
                            }}
                          />
                        }
                        label={`Враховувати порядок слів для оцінки співпадіння`}
                      />
                    </li>
                    <li>
                      <div className="flex-row w-full h-16">
                        <div className="w-1/5 float-left">
                          <NumberInput
                            aria-label="Relevance filter limiter"
                            min={0}
                            max={100}
                            defaultValue={this.state.relevanceFilterLimit}
                            onChange={(_, relevanceFilterLimit) =>
                              this.setState(
                                {
                                  relevanceFilterLimit: relevanceFilterLimit || 0,
                                },
                                () => localStorage.setItem('relevanceFilterLimit', `${relevanceFilterLimit || 0}`),
                              )
                            }
                          />
                        </div>
                        <div className="w-3/5  float-left leading-10">{`Нижній поріг % співпадіння для фільтру`}</div>
                      </div>
                    </li>
                  </li>
                </ul>
              </div>
              <div className="h-96 w-full pr-2">
                <h4 className="text-2xl border-b border-gray-200 pb-2">Чорний список доменів</h4>
                {Array.from(this.state.domainFilter).map((item: any, index) => (
                  <button
                    key={`item${index}`}
                    name={`item${index}`}
                    className="rounded-full bg-blue-100 p-4 hover:cursor-pointer"
                    onClick={() => this.removeDomainFilter(item)}
                  >
                    {item}
                    <ClearIcon />
                  </button>
                ))}
              </div>
            </div>
            <div className="basis-1/3 border-l-2 border-gray-200">
              <div className="h-[40rem] overflow-y-auto ml-4">
                <h4 className="text-2xl border-b border-gray-200">
                  Ресурси{' '}
                  <span className="text-gray-400 text-sm pb-2">(обирайте лише ті ресурси, що Вас цікавлять)</span>
                </h4>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.allSitesEnabled}
                      onChange={() => {
                        SITES.forEach(({ title }) => this.toggleSite(title, !this.state.allSitesEnabled))
                        this.setState({
                          allSitesEnabled: !this.state.allSitesEnabled,
                        })
                      }}
                    />
                  }
                  label={`Обрати всі`}
                />

                {SITES.map(({ title, description }) => (
                  <div key={title}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.sitesEnabled.has(title)}
                          onChange={() => this.toggleSite(title)}
                        />
                      }
                      label={`${title} - ${description}`}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    )
  }

  /**
   * renderControlButtons
   *
   * @returns
   */
  renderControlButtons() {
    return (
      <div>
        {this.renderPreferences()}

        <Button title="Перезапустити пошук" disabled={this.state.isLoading} onClick={() => this.onClickRunOrPause()}>
          <CachedIcon />
        </Button>
      </div>
    )
  }

  /**
   * renderShowNothing
   *
   * @returns
   */
  renderShowNothing() {
    const searchResults = this.state.searchResults.get(this.getSiteFilter()) || []
    const hiddentItems = searchResults.length - this.state.filteredSearchResults.length

    return (
      <li key="nothing" className="text-gray-200 text-2xl m-24 min-w-96 text-center">
        {hiddentItems ? (
          <div>
            <p>Тут є {hiddentItems} рядків(-ок) прихованими</p>
            <br />
            <p
              onClick={() => this.setState({ enableStrictMatchFinter: false }, () => this.onFilter())}
              className="hover:underline hover:cursor-pointer"
            >
              Показати їх
            </p>
          </div>
        ) : (
          <>
            <p>Немає результатів</p>
            <br />
            <br />
            <p className="text-sm">Ймовірно пошук не знайшов нічого по Вашому запиту.</p>
            <p className="text-sm">Спробуйте змінити запит та/або перезавнтажити сторінку.</p>
          </>
        )}
      </li>
    )
  }

  renderSearchResultItem(result: SearchResult, index: number) {
    const looksLikeRelevant = result?.relevanceMark && result.relevanceMark > 80

    const disableClearButton = result.link && this.state.site && this.state.site !== 'default'

    return (
      <li key={`${index}-${Date.now()}`} className={`hover:bg-gray-100 p-2`}>
        <button
          title="Click here is you want to eliminate this domaint from search results furthermore"
          name={`item${index}`}
          className={`rounded-full float-right p-4 mx-1 
          ${disableClearButton ? 'bg-gray-100 text-gray-300' : 'hover:cursor-pointer hover:bg-gray-300 bg-gray-200'}`}
          onClick={() => !disableClearButton && this.addDomainFilter(result.link)}
        >
          <ClearIcon />
        </button>

        <UnsafeResourceLink
          className="rounded-full bg-gray-200 float-right p-4 mx-1 hover:cursor-pointer  hover:bg-gray-300"
          href={result.link}
        >
          <OpenInNewIcon />
        </UnsafeResourceLink>
        {result.cacheLink ? (
          <div className="float-right" title="View cached page">
            <ModalPreviewPage title={result.title} url={result.cacheLink} filter={this.state.filter} />
          </div>
        ) : (
          <button
            title="Preview is not possible"
            className="rounded-full bg-gray-200 float-right p-4 mx-1 text-gray-300 hover:bg-gray-300"
            disabled
          >
            <PreviewIcon />
          </button>
        )}
        <div
          className="flex flex-row"
          onClick={() => {
            this.props.onItemClick && this.props.onItemClick(result, this.state.site)
          }}
        >
          <div className="w-[7rem]">
            <div>
              <p>Співпадає</p>
              <p className={`text-center text-3xl`}>
                {looksLikeRelevant ? <StarsIcon className="text-green-400" /> : ''}
                {result.relevanceMark ? result.relevanceMark : 0}%
              </p>
            </div>
          </div>
          <div className="basis-11/12">
            <img className="float-left mr-2 h-px:20 w-px:20" src={result.image} />
            <p className="text-gray-900 text-xl underline">
              {(result.highlighedTitle ? result.highlighedTitle : result.title) as any as Element}
            </p>
            <p className="text-blue-900 w-96">
              <div className="truncate">{result.link}</div>
            </p>
            <p className="font-bold">
              {(result.highlighedSnippet ? result.highlighedSnippet : result.snippet) as any as Element}
            </p>
          </div>
        </div>
      </li>
    )
  }

  renderFilterInput(): React.ReactNode {
    let timeoutId: number | NodeJS.Timeout | null = null

    return (
      <div className={`w-full ${!this.state.filterEnabled && 'text-gray-400 bg-gray-200'}`}>
        <CustomWidthTooltip
          title={
            <div className="w-96 text-lg">
              <ul>
                Приклади застосування фільтру:
                <li>
                  &#34;123 отдельная мотострелковая бригада&#34; - буде намагатись знайти всі слова розділені пробілом.
                </li>
                <li>
                  &#34;123 отдельная мотострелковая бригада;123 омсбр&#34; - використання двокрапки дозволить
                  застосувати кілька незалежних варіантів фільтрів.
                </li>
              </ul>
            </div>
          }
        >
          <TextField
            id="filter-outlined-basic"
            label="Filter"
            variant="outlined"
            className="w-full focus:outline-none p-2 -mt-2 float-left border-solid border-2 bg-white"
            type="text"
            value={this.state.filter}
            onChange={(e) => {
              if (timeoutId !== null) {
                clearTimeout(timeoutId)
                timeoutId = null
              }
              this.setState({ filter: e.target.value })

              timeoutId = setTimeout(() => this.doFilter(), 300)
            }}
            placeholder="Filter"
            disabled={!this.state.filterEnabled}
            inputProps={{ min: 0 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <div
                    className="rounded-full float-left -ml-1 p-2 border-solid border-0 cursor-pointer hover:bg-gray-200"
                    title="Toggle filter"
                    onClick={() => {
                      this.setState({ filterEnabled: !this.state.filterEnabled }, () => this.doFilter())
                    }}
                  >
                    {this.state.filterEnabled ? (
                      <FilterAltIcon className="m-0 p-0" />
                    ) : (
                      <FilterAltOffIcon className="m-0 p-0" />
                    )}
                  </div>
                </InputAdornment>
              ),
              endAdornment: (
                <>
                  <InputAdornment position="start">
                    <div
                      title="Toggle strict filter mode"
                      className={`rounded-full m-0 p-2 border-solid border-0 cursor-pointer ${
                        this.state.filterEnabled
                          ? this.state.enableStrictMatchFinter
                            ? 'bg-green-500 text-white'
                            : 'bg-gray-100 text-black'
                          : 'bg-gray-200'
                      }`}
                      onClick={() =>
                        this.setState(
                          {
                            enableStrictMatchFinter: !this.state.enableStrictMatchFinter,
                          },
                          () => this.doFilter(),
                        )
                      }
                    >
                      <BoltIcon />
                    </div>
                  </InputAdornment>
                  <InputAdornment position="end">
                    <div
                      title="Toggle hightlighting matches"
                      onClick={() => {
                        this.setState({
                          enableHightlight: !this.state.enableHightlight,
                        })
                      }}
                      className={` rounded-full m-0 p-2 border-solid border-0 cursor-pointer ${
                        this.state.enableHightlight ? 'bg-yellow-400 text-white hover:bg-yellow-300' : ''
                      }`}
                    >
                      <TipsAndUpdatesOutlinedIcon />
                    </div>
                  </InputAdornment>
                </>
              ),
            }}
          />
        </CustomWidthTooltip>
      </div>
    )
  }

  /**
   * render
   *
   * @returns
   */
  render(): React.ReactNode {
    const searchResults = this.state.searchResults.get(this.getSiteFilter()) || []
    return (
      <div className="max-h-screen">
        <div className="flex flex-row h-16 w-full z-1 bg-white">
          <div className="w-1/3">{this.renderFilterInput()}</div>
          <div className="w-2/3">
            <div className="float-right">{this.renderControlButtons()}</div>
            <div className="">{this.renderSiteButtons()}</div>
          </div>
        </div>
        <div className="overflow-y-scroll m-0 h-[calc(130vh-12rem)]">
          <ul className="bg-grey-100 m-0 h-full">
            {this.state.filteredSearchResults.map((result, index) => this.renderSearchResultItem(result, index))}
            {((this.state.filteredSearchResults.length < searchResults.length && this.state.enableStrictMatchFinter) ||
              !this.state.filteredSearchResults.length) &&
            !this.state.isLoading
              ? this.renderShowNothing()
              : ''}
            {this.state.isLoading ? (
              <li key="loading">
                <Loading />
              </li>
            ) : null}
          </ul>
        </div>
      </div>
    )
  }
}
