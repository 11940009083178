import { Component, MouseEventHandler, PropsWithChildren, ReactNode } from 'react'

export type ButtonProps = {
  className?: string
  onClick?: MouseEventHandler | undefined
  disabled?: boolean
  title?: string
}

export default class Button extends Component<PropsWithChildren<ButtonProps>> {
  render(): ReactNode {
    let className = `border rounded p-4 border-0 ml-1`

    if (this.props.disabled) {
      className += ` text-grey-400`
    } else {
      className += ` active:bg-gray-400 hover:bg-gray-200 text-black`
    }

    return (
      <button
        className={className + ' ' + (this.props.className ? this.props.className : 'bg-gray-100')}
        type="button"
        onClick={this.props.onClick}
        disabled={this.props.disabled}
        title={this.props.title}
      >
        {this.props.children}
      </button>
    )
  }
}
