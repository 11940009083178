import React, { MouseEventHandler, PropsWithChildren } from 'react'
import axios from 'axios'
import { PublicIPContext, PublicIPContextType } from '../../Contexts'
import { AccessUnsafeResourceEvent, AccessUnsafeResourceNativeEvent } from '../../Contexts'

const GET_COUNTRY_URL = 'https://tnedi.me/json'
const RESTRICTED_COUNTRY_NAME = 'Ukraine'

export default function PublicIPCheckDialog({ children }: PropsWithChildren) {
  return (
    <PublicIPContext.Provider value={{ checkPublicIP } satisfies PublicIPContextType}>
      {children}
    </PublicIPContext.Provider>
  )
}

class AccessUnsafeResourceNativeEventImpl extends MouseEvent implements AccessUnsafeResourceNativeEvent {
  isChecked = false
}

async function checkPublicIP<T>(event: AccessUnsafeResourceEvent<T>, cb?: MouseEventHandler<T>) {
  if (isAccessUnsafeResourceEvent<T>(event) && event.nativeEvent.isChecked) {
    return
  }

  const newEvent = new AccessUnsafeResourceNativeEventImpl(event.type, event.nativeEvent)

  event.preventDefault()
  event.stopPropagation()

  const publicIPAcceptable = await isPublicIPAcceptable()

  if (!publicIPAcceptable) {
    const answer = window.confirm(
      'Following the link may result in unwanted disclosure of your public IP address. Are you sure you want to continue?',
    )

    if (!answer) return
  }

  newEvent.isChecked = true

  if (cb) {
    return cb(newEvent as unknown as React.MouseEvent<T>)
  }

  event.target.dispatchEvent(newEvent)
}

async function isPublicIPAcceptable() {
  const countryName = await getCountryName()

  if (!countryName) {
    throw new Error('Could not retrieve public IP location')
  }

  return countryName !== RESTRICTED_COUNTRY_NAME
}

async function getCountryName() {
  const {
    data: { country },
  } = await axios.get<{ country: string }>(GET_COUNTRY_URL)

  return country
}

function isAccessUnsafeResourceEvent<T>(event: React.MouseEvent<T>): event is AccessUnsafeResourceEvent<T> {
  return 'nativeEvent' in event && event.nativeEvent instanceof AccessUnsafeResourceNativeEventImpl
}
