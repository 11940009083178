import React, { Component } from 'react'
import PlaceIcon from '@mui/icons-material/Place'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import Loading from './common/Loading'
import { backendClient } from '../api/backendClients'
import { UnsafeResourceLink } from './common/UnsafeResourceLink'

type Data = { name: string; address: string }

type LegalDataTableProps = {
  name: string
  inn: string
  ogrn: string
  onLoad?: (data: Data) => any
}
type LegalCase = any
type LegalDataTableState = {
  data: any
  legalCases: LegalCase
  isLoading: boolean
}

export default class LegalDataTable extends Component<LegalDataTableProps, LegalDataTableState> {
  constructor(props: LegalDataTableProps) {
    super(props)

    this.state = {
      data: null,
      isLoading: false,
      legalCases: [],
    }
  }

  async componentDidMount(): Promise<void> {
    if (!this.props.name) return

    this.setState({ isLoading: true })
    try {
      const query = this.props.ogrn || this.props.name // TODO add validation

      const limit = 10
      let i = 0
      let response
      while (!response && i++ < limit) {
        try {
          response = await backendClient.get(`/api/Parser/data/${query}`)
        } catch (err) {
          if (err?.response?.status !== 429) {
            break
          }
        }
      }

      this.setState({ data: response, isLoading: false })

      if (response?.ogrn) {
        const legalCasesResponse = backendClient.get<LegalCase>(`/api/Parser/legal-case/${response.ogrn}`)

        this.setState({ legalCases: legalCasesResponse })
      }

      this.setState({ isLoading: false })

      this.props.onLoad && this.props.onLoad(response as any as Data)
    } catch (err) {
      console.log('LegalDataTable', err)
      this.setState({ isLoading: false })
    }
  }

  showComparison(officialValue: string, incomeValue: string) {
    if (!incomeValue) {
      return officialValue
    }
    if (incomeValue?.toString() === officialValue?.toString()) {
      return <span className="bg-green-200">{officialValue}</span>
    }

    return (
      <span>
        <span className="bg-red-200 line-through">{incomeValue}</span>
        <span className="bg-green-200">{officialValue}</span>
      </span>
    )
  }

  renderGeneralInfo() {
    return (
      <ul className="">
        <li key="ogrn" className="hover:bg-gray-200 py-2">
          <b>ОГРН</b> <p className="float-right">{this.showComparison(this.state.data?.ogrn, this.props.ogrn)}</p>
        </li>

        <li key="inn" className="hover:bg-gray-200 py-2">
          <b>ІНН</b>
          <p className="float-right">{this.showComparison(this.state.data?.inn, this.props.inn)}</p>
        </li>

        <li key="name" className="hover:bg-gray-200 py-2  flex w-full">
          <b>Скорочена назва</b>
          <p className="float-right">{this.state.data?.name}</p>
        </li>

        <li key="fullName" className="hover:bg-gray-200 py-2 flex w-full">
          <p className="align-middle w-1/6">
            <b>Назва організації</b>
          </p>
          <p className="w-5/6 float-right text-right">
            {this.showComparison(this.state.data?.fullName, this.props.name)}
          </p>
        </li>

        <li key="address" className="hover:bg-gray-200 py-2 w-full flex">
          <b className="w-1/6">Юридчна адреса</b>
          <a title="Open location" className="float-right w-5/6 p-0 underline hover:cursor-pointer text-right">
            {this.state.data?.address}
            <PlaceIcon className="text-gray-700" />
          </a>
        </li>
        <li key="url" className="hover:bg-gray-200 py-2">
          <b>Профіль</b>{' '}
          <p className="float-right">
            <UnsafeResourceLink
              href={`${this.state.data?.url}`}
              target={'_blank'}
              className="underline hover:cursor-pointer"
              title="Click to open source in new tab"
            >
              {this.state.data?.url} <OpenInNewIcon className="text-gray-700" />
            </UnsafeResourceLink>
          </p>
        </li>
        <li key="startRegistration" className="hover:bg-gray-200 w-full py-2 flex">
          <b>Статус</b>
          <p className="float-right w-5/6 text-right">
            {this.state.data?.startRegistration} -{' '}
            {this.state.data?.endRegistration?.indexOf('Діюча') === -1 ? (
              <span className="text-red-600">{this.state.data.endRegistration}</span>
            ) : (
              <span className="text-green-600">{this.state.data?.endRegistration}</span>
            )}
          </p>
        </li>
      </ul>
    )
  }

  renderNothing() {
    return <div className="text-3xl gray px-6 py-3">...</div>
  }

  renderOtherUnitsOnTheSameAddress() {
    return (
      <div>
        <h3 className="text-xl py-2">Інші організації за цією ж адресою</h3>
        {this.state.data?.sameAddressList?.data?.length ? (
          <ul className="max-h-96 overflow-y-auto list-disc">
            {this.state.data?.sameAddressList?.data?.map(({ name, link }: any, i: number) => (
              <li key={`relations-${i}`} className="hover:bg-gray-200 py-1">
                <a href={link} className="hover:underline" title="Click to open source in new tab">
                  {name}
                </a>
              </li>
            ))}
          </ul>
        ) : (
          this.renderNothing()
        )}
      </div>
    )
  }

  renderManagement() {
    console.log(
      'this.state.data?.management?.data',
      this.state.data?.management?.data?.length,
      this.state.data?.management?.data,
    )
    return (
      <div className="w-full">
        <h3 className="text-xl py-2">Керівництво</h3>
        <ul className="max-h-96 overflow-y-auto w-full">
          {this.state.data?.management?.data?.length
            ? this.state.data?.management?.data.map(({ position, name, inn, since, link }: any, i: number) => (
                <li key={`${inn}-${i}`} className="hover:bg-gray-200 flex w-full">
                  <UnsafeResourceLink
                    href={link}
                    className="underline hover:cursor-pointer hover:underline w-full py-3"
                    title="Click to open source in new tab"
                  >
                    <span className="w-1/6 ">{since}</span>
                    <span className="w-4/6 float-right text-right">
                      {position} {name} {inn}
                    </span>
                  </UnsafeResourceLink>
                </li>
              ))
            : this.renderNothing()}
        </ul>
      </div>
    )
  }

  renderRegistrationChangeRecords() {
    return (
      <div className="">
        <h3 className="text-xl py-2">Записи в реєстрі</h3>
        <ul className="h-[45rem] overflow-y-auto">
          {this.state.data?.registrationRecords?.data?.length
            ? this.state.data?.registrationRecords?.data.map(({ date, description }: any, i: number) => (
                <li key={`${date}-${i}`} className="hover:bg-gray-200 py-1 flex w-full">
                  <b className="w-1/6 text-s">{date}</b>{' '}
                  <span className="w-5/6 float-right text-right">{description}</span>
                </li>
              ))
            : this.renderNothing()}
        </ul>
      </div>
    )
  }

  renderDirections() {
    return (
      <div className="">
        <h3 className="text-xl py-2">Види діяльності</h3>
        <ul className="max-h-96 overflow-y-auto">
          {this.state.data?.activities?.data?.length
            ? this.state.data?.activities?.data?.map(({ kved, description }: any, i: number) => (
                <li key={`${kved}-${i}`} className="hover:bg-gray-200 py-1 flex w-full">
                  <b className="w-1/6">{kved}</b> <span className="float-right w-5/6">{description}</span>
                </li>
              ))
            : this.renderNothing()}
        </ul>
      </div>
    )
  }

  renderLegalCases() {
    return (
      <div className="">
        <h3 className="text-xl py-2">Судові справи</h3>
        <ul className="h-[45rem] overflow-y-auto">
          {this.state.legalCases?.data?.length
            ? this.state.legalCases?.data?.map(({ link, caseNumber, received, description }: LegalCase, i: number) => (
                <li key={`${caseNumber}-${i}`} className="hover:bg-gray-200 py-1 flex w-full">
                  <UnsafeResourceLink
                    href={link}
                    title="Click to open source in new tab"
                    className="hover:cursor-pointer hover:underline w-full"
                  >
                    <p>
                      <b>{caseNumber}</b> <span className="">{received}</span>
                    </p>
                    <span className="float-right w-5/6">{description}</span>
                  </UnsafeResourceLink>
                </li>
              ))
            : this.renderNothing()}
        </ul>
      </div>
    )
  }

  render(): React.ReactNode {
    return this.state.isLoading ? (
      <Loading />
    ) : this.state.data ? (
      <div className="flex w-full">
        <div className="flex flex-row h-full w-full">
          <div className="basis-1/4 p-2">
            <div className="bg-gray-100 rounded-md p-2">{this.renderGeneralInfo()}</div>
            <div className="bg-gray-100 rounded-md p-2 mt-2">{this.renderManagement()}</div>
          </div>

          <div className="basis-1/4 p-2">
            <div className="bg-gray-100 rounded-md p-2">{this.renderOtherUnitsOnTheSameAddress()}</div>
            <div className="bg-gray-100 rounded-md p-2  mt-2">{this.renderDirections()}</div>
          </div>

          <div className="basis-1/4 p-2">
            <div className="bg-gray-100 rounded-md p-2">{this.renderRegistrationChangeRecords()}</div>
          </div>

          <div className="basis-1/4 p-2">
            <div className="bg-gray-100 rounded-md p-2">{this.renderLegalCases()}</div>
          </div>
        </div>
      </div>
    ) : (
      <div className="text-center p-10 mt-12 gw-full text-gray-200 text-3xl">Інформацію не знайдено</div>
    )
  }
}
