import axios, {
  AxiosInterceptorManager,
  AxiosInterceptorOptions,
  AxiosResponse,
  CreateAxiosDefaults,
  InternalAxiosRequestConfig,
} from 'axios'
import { CacheOptions, setupCache, CacheRequestConfig } from 'axios-cache-interceptor'

declare module 'axios-cache-interceptor' {
  interface AxiosCacheInstance {
    request<T = any, D = any>(config?: CacheRequestConfig<T, D>): Promise<T>
    get<T = any, D = any>(url: string, config?: CacheRequestConfig<T, D>): Promise<T>
    delete<T = any, D = any>(url: string, config?: CacheRequestConfig<T, D>): Promise<T>
    head<T = any, D = any>(url: string, config?: CacheRequestConfig<T, D>): Promise<T>
    options<T = any, D = any>(url: string, config?: CacheRequestConfig<T, D>): Promise<T>
    post<T = any, D = any>(url: string, data?: D, config?: CacheRequestConfig<T, D>): Promise<T>
    postForm<T = any, D = any>(url: string, data?: D, config?: CacheRequestConfig<T, D>): Promise<T>
    put<T = any, D = any>(url: string, data?: D, config?: CacheRequestConfig<T, D>): Promise<T>
    putForm<T = any, D = any>(url: string, data?: D, config?: CacheRequestConfig<T, D>): Promise<T>
    patch<T = any, D = any>(url: string, data?: D, config?: CacheRequestConfig<T, D>): Promise<T>
    patchForm<T = any, D = any>(url: string, data?: D, config?: CacheRequestConfig<T, D>): Promise<T>
  }
}

type Interceptor<T> = {
  onFulfilled?: Parameters<AxiosInterceptorManager<T>['use']>[0]
  onRejected?: Parameters<AxiosInterceptorManager<T>['use']>[1]
  options?: AxiosInterceptorOptions
}

export type InterceptorConfig = {
  request?: Interceptor<InternalAxiosRequestConfig>
  response?: Interceptor<AxiosResponse>
}

interface AxiosInstanceConfig extends CreateAxiosDefaults {
  interceptors?: InterceptorConfig[]
  cache?: CacheOptions
}

export function createAxiosInstance({ cache, interceptors, ...options }: AxiosInstanceConfig) {
  const instance = axios.create(options)

  interceptors?.forEach(({ request, response }) => {
    if (request) {
      instance.interceptors.request.use(request.onFulfilled, request.onRejected, request.options)
    }

    if (response) {
      instance.interceptors.response.use(response.onFulfilled, response.onRejected, response.options)
    }
  })

  const cacheInstance = setupCache(instance, cache || { headerInterpreter: () => 'dont cache' })

  instance.interceptors.response.use((res) => res?.data, null, {
    synchronous: true,
  })

  return cacheInstance
}
