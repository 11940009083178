import { ALLOWED_PROVIDERS } from '../MapsWrapper/types'

export const VIEW_TYPE_FOUR_TILES = 'view-type-four-tiles'
export const VIEW_TYPE_MAIN_WITH_MINI = 'view-type-main-with-mini'
export const VIEW_TYPE_ONE_MAP = 'view-type-one-map'
export const VIEW_TYPE_LOADING = 'view-type-loading'

export const MAP_CONTAINER_ID_FIRST = 'map-first'
export const MAP_CONTAINER_ID_SECOND = 'map-second'
export const MAP_CONTAINER_ID_THIRD = 'map-third'
export const MAP_CONTAINER_ID_FOURTH = 'map-fourth'

export const MAP_GOOGLE = 'google'
export const MAP_YANDEX = 'yandex'
export const MAP_OSM = 'osm'
export const MAP_WIKI = 'wiki'

export const PROVIDERS: Array<ALLOWED_PROVIDERS> = [MAP_GOOGLE, MAP_WIKI, MAP_OSM, MAP_YANDEX]
export const DEFAULT_LAT = 55.750942
export const DEFAULT_LNG = 37.6259468
export const DEFAULT_ZOOM = 16
export const LAYER_TYPE_STREET = 'Street'
export const LAYER_TYPE_SATELLITE = 'Satellite'
export const LAYER_TYPE_HYBRID = 'Hybrid'
export const LAYER_TYPE_TERRAIN = 'Terrain'

export const STORE_KEY = 'map-store'
