import { useQuery } from '@tanstack/react-query'
import Highlight from '../../common/Highlight'
import Loading from '../../common/Loading'
import { WikimapiaAPI } from '../utils/WikimapiaAPI'
import { ShowNothing } from './ShowNothing'

type WikimapiaSearchHistoryProps = {
  searchFilter: string
  searchResultID: number
}

export function WikimapiaSearchHistory({ searchFilter, searchResultID }: WikimapiaSearchHistoryProps) {
  const { data, error, isLoading } = useQuery({
    queryKey: ['wikimapia-search', 'history', searchResultID],
    queryFn: () => WikimapiaAPI.history(searchResultID),
  })

  if (error) {
    return <ShowNothing />
  }

  if (isLoading) {
    return <Loading />
  }

  if (data) {
    return (
      <div className="flex flex-col h-auto w-full overflow-y-scroll">
        <div className="flex justify-between items-center">
          <div className="flex items-center pb-2">
            <b className="text-base font-normal">ID:&nbsp;</b>
            <i className="font-extralight text-sm">{searchResultID}</i>
          </div>
          <div>
            {data.length > 0 && searchFilter.trim() !== '' && (
              <div className="text-sm font-light">
                Matched&nbsp;
                <b className="text-normal font-medium">
                  &quot;
                  <span className="w-20 truncate">{searchFilter}</span>
                  &quot;
                </b>
                :
                {data.reduce((count, h) => {
                  if (h.content) {
                    h.content.indexOf(searchFilter) !== -1 && ++count
                  }

                  return count
                }, 0)}{' '}
                times
              </div>
            )}
          </div>
        </div>
        <hr />
        <div>
          <ul>
            {data.map((item, index) => (
              <li key={`${index}-${Date.now()}`} className="py-2">
                <div className="text-base font-light pb-1 pt-3">
                  {item.title} <i>{item.date}</i>
                </div>
                <hr />
                <div className="font-extralight text-sm py-1 hover:bg-stone-100">
                  <Highlight query={searchFilter}>{item.content}</Highlight>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    )
  }

  return <ShowNothing />
}
