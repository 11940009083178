import { LAYER_TYPE_HYBRID, LAYER_TYPE_SATELLITE, LAYER_TYPE_STREET, LAYER_TYPE_TERRAIN } from '../constants'
import { CreateMapOptions, LAYER_TYPE } from '../../MapsWrapper/types'
import { control, Map, TileLayer } from 'leaflet'
import { ProviderInterface } from './index'

const LAYERS_URL = {
  [LAYER_TYPE_STREET]: 'http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
  [LAYER_TYPE_SATELLITE]: 'http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',
  [LAYER_TYPE_HYBRID]: 'http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}',
  [LAYER_TYPE_TERRAIN]: 'http://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}',
}

export default class GoogleProvider implements ProviderInterface {
  public async create(options: CreateMapOptions): Promise<Map> {
    const map = new Map(options.id, options)
    const layers = this.layers()
    map.addLayer(layers[options.currentLayerType ? (options.currentLayerType as LAYER_TYPE) : LAYER_TYPE_STREET])
    control.layers(layers).addTo(map)
    return map
  }

  private layers(): Record<LAYER_TYPE, TileLayer> {
    return {
      [LAYER_TYPE_STREET]: this.tileLayer(LAYER_TYPE_STREET),
      [LAYER_TYPE_SATELLITE]: this.tileLayer(LAYER_TYPE_SATELLITE),
      [LAYER_TYPE_HYBRID]: this.tileLayer(LAYER_TYPE_HYBRID),
      [LAYER_TYPE_TERRAIN]: this.tileLayer(LAYER_TYPE_TERRAIN),
    }
  }

  private tileLayer(t: LAYER_TYPE): TileLayer {
    return new TileLayer(LAYERS_URL[t], {
      accessToken: t,
      subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
      attribution: '&copy; <a href="https://www.google.com.ua/maps/">GoogleMaps</a>',
    })
  }
}
