import { VIEW_TYPE } from '../../MapsWrapper/types'
import { Button, ButtonGroup, IconButton, ToggleButton, ToggleButtonGroup } from '@mui/material'
import Window from '@mui/icons-material/Window'
import ViewSidebar from '@mui/icons-material/ViewSidebar'
import Square from '@mui/icons-material/Square'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'

import React, { useState } from 'react'
import {
  MAP_GOOGLE,
  MAP_OSM,
  MAP_WIKI,
  MAP_YANDEX,
  VIEW_TYPE_FOUR_TILES,
  VIEW_TYPE_MAIN_WITH_MINI,
  VIEW_TYPE_ONE_MAP,
} from '../constants'
import { LatLng } from 'leaflet'
import OSMSearchComponent from './OSMSearchComponent'
import GpsFixedIcon from '@mui/icons-material/GpsFixed'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import Utils from '../../common/Utils'

import googleMapIcon from '../icons/google-maps.svg'
import yandexMapIcon from '../icons/yandex-maps.svg'
import osmIcon from '../icons/osm-icon.png'
import wikiIcon from '../icons/wiki-icon.png'
import { providerLinkByCoordinates } from '../utils'
import { UnsafeResourceMUIButton } from '../../common/UnsafeResourceMUIButton'

type MapsNavigationComponentProps = {
  viewType: VIEW_TYPE
  center: LatLng
  zoom: number
  changeViewType: (event: any, newType: VIEW_TYPE) => void
  moveToPosition: (event: any, position: LatLng, description?: string) => void
  moveToLastPosition: (event: any) => void
}

export default function MapsNavigationComponent({
  viewType,
  center,
  zoom,
  changeViewType,
  moveToPosition,
  moveToLastPosition,
}: MapsNavigationComponentProps) {
  const [open, setOpen] = useState(true)

  const changeOpen = () => setOpen(!open)

  return (
    <div className="navi absolute backdrop-blur-sm bg-white">
      <div className="navi-items flex h-10 p-1">
        <div className="btn-block flex pr-1">
          <IconButton
            className="w-8 h-8"
            title={`${open ? 'Collapse' : 'Expand'} navigation panel`}
            onClick={changeOpen}
          >
            {open ? <KeyboardArrowLeftIcon /> : <KeyboardArrowRight />}
          </IconButton>
        </div>
        {open && <OSMSearchComponent onSelect={moveToPosition} />}
        {open && (
          <div className="btn-block flex px-1">
            <ToggleButtonGroup value={viewType} exclusive onChange={changeViewType}>
              <ToggleButton value={VIEW_TYPE_FOUR_TILES} aria-label="module" title="Change maps view to four tiles">
                <Window />
              </ToggleButton>
              <ToggleButton
                value={VIEW_TYPE_MAIN_WITH_MINI}
                aria-label="quilt"
                title="Change maps view to main with mini tiles"
              >
                <ViewSidebar />
              </ToggleButton>
              <ToggleButton value={VIEW_TYPE_ONE_MAP} aria-label="quilt" title="Change maps view to single tile">
                <Square />
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        )}
        {open && (
          <div className="btn-block flex pr-1">
            <ToggleButton title="Move to last marker" value="center" aria-label="bold">
              <GpsFixedIcon onClick={moveToLastPosition} />
            </ToggleButton>
          </div>
        )}
        {open && (
          <div className="btn-block flex pr-1">
            <ButtonGroup>
              <Button
                size="small"
                title="Open google maps in a new tab"
                className="w-12 min-w-0 mx-1"
                onClick={() => providerLinkByCoordinates(MAP_GOOGLE, new LatLng(center.lat, center.lng), zoom)}
              >
                <img className="w-[24px]" src={googleMapIcon} alt="yandex" />
              </Button>
              <UnsafeResourceMUIButton
                size="small"
                title="Open yandex maps in a new tab"
                className="w-12 min-w-0 mx-1"
                onClick={() => providerLinkByCoordinates(MAP_YANDEX, new LatLng(center.lat, center.lng), zoom)}
              >
                <img className="w-[24px]" src={yandexMapIcon} alt="yandex" />
              </UnsafeResourceMUIButton>
              <Button
                size="small"
                title="Open openstreetmaps in a new tab"
                className="w-12 min-w-0 mx-1"
                onClick={() => providerLinkByCoordinates(MAP_OSM, new LatLng(center.lat, center.lng), zoom)}
              >
                <img className="w-[24px]" src={osmIcon} alt="yandex" />
              </Button>
              <UnsafeResourceMUIButton
                size="small"
                title="Open wikimapia maps in a new tab"
                className="w-12 min-w-0 mx-1"
                onClick={() => providerLinkByCoordinates(MAP_WIKI, new LatLng(center.lat, center.lng), zoom)}
              >
                <img className="w-[24px]" src={wikiIcon} alt="yandex" />
              </UnsafeResourceMUIButton>
            </ButtonGroup>
            <div className="flex">
              <IconButton title="Copy coordinates to clipboard">
                <ContentCopyIcon onClick={() => Utils.copyToClipboard(`${center.lng}, ${center.lat}`)} />
              </IconButton>
              <span className="underline flex items-center justify-center">
                {center.lat.toFixed(5)}, {center.lng.toFixed(5)}
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
