import React, { Component } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import VpnLockOutlinedIcon from '@mui/icons-material/VpnLockOutlined'
import { searchBackendClient } from '../../api/searchBackendClient'

type PublicIpProps = { onSuccess?: (country: string) => any }
type PublicIpState = { country: string }

export default class PublicIp extends Component<PublicIpProps, PublicIpState> {
  constructor(props: PublicIpProps) {
    super(props)
    this.state = {
      country: '',
    }
  }

  async componentDidMount(): Promise<void> {
    try {
      const country = await this.retrieveLocation()

      if (!country) {
        throw new Error('Could retrieve public location')
      }

      if (/^[a-zA-Z\s]+$/.test(country.trim())) {
        if (country.indexOf('Ukraine') !== -1 && !window.confirm(`Are you OK to send requests from ${country} ?`)) {
          return
        }
        this.setState(() => ({ country }))
        this.props.onSuccess && this.props.onSuccess(country)
      } else {
        console.error('Unexpected response while check public location', country)
      }
    } catch (err) {
      console.log('Error at PublicIp component', err)
    }
  }

  async retrieveLocation() {
    let countryName
    let attempts = 0
    while (!countryName) {
      ++attempts
      try {
        countryName = await searchBackendClient.get('/v1/location')
      } catch (err) {
        console.error('Error while requesting public location', { err })
      }
    }
    console.log(`Public location info`, { countryName, attempts })
    return countryName
  }

  renderLoading() {
    return (
      <div className="scale-50 -mt-1">
        <CircularProgress color="inherit"></CircularProgress>
      </div>
    )
  }

  render(): React.ReactNode {
    return (
      <div className="p-3" title="The server has stablished anonynous connection">
        <div className="float-left text-gray-700">
          <VpnLockOutlinedIcon className="-mt-1" />
        </div>
        <div className="float-left">
          {this.state.country ? <span className="my-2 truncate">{this.state.country}</span> : this.renderLoading()}
        </div>
      </div>
    )
  }
}
