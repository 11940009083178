import { IconButton } from '@mui/material'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined'
import Utils from '../../common/Utils'
import Highlight from '../../common/Highlight'
import { UnsafeResourceLink } from '../../common/UnsafeResourceLink'
import { WikimapiaBoxResultItem, WikimapiaSearchResultItem } from '../types'

type WikimapiaSearchResultsListProps = {
  filter: string
  item: WikimapiaSearchResultItem | WikimapiaBoxResultItem
  onClick: () => void
}

export function WikimapiaSearchResult({ filter, item, onClick }: WikimapiaSearchResultsListProps) {
  return (
    <div
      className="flex w-full h-36 p-2 cursor-pointer hover:bg-stone-100"
      title="Click to get more info"
      onClick={onClick}
    >
      <div className="flex flex-col w-4/5">
        <div className="flex w-full h-auto items-center justify-start text-sm font-medium py-1">
          <Highlight query={filter}>{item.title}</Highlight>
        </div>
        {'address' in item && (
          <div className="flex w-full h-auto text-xs items-center justify-start font-serif font-extralight py-1">
            {item.address}
          </div>
        )}
        <div className="flex w-full h-auto text-xs items-center justify-start italian font-extralight py-1">
          {item.lat} {item.lon}
        </div>
      </div>
      <div className="flex flex-col w-1/5 h-full items-center justify-around">
        <IconButton
          className={'w-10 h-10'}
          onClick={() => Utils.copyToClipboard(item.geoJsonFeature)}
          title="Copy feature to clipboard"
        >
          <ContentCopyOutlinedIcon />
        </IconButton>
        <UnsafeResourceLink href={`//wikimapia.org/${item.id}`} title="Open wikimapia link">
          <OpenInNewOutlinedIcon />
        </UnsafeResourceLink>
      </div>
    </div>
  )
}
