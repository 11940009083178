import { parseValidSearchParams } from './searchParametersParser'
import type { SearchParameterValue } from './searchParametersParser'

export const readUrlSearchParameters = (): URLSearchParams => new URLSearchParams(document.location.search)

export const writeUrlSearchParameters = (urlSearchParams: URLSearchParams): void => {
  document.location.search = '?' + urlSearchParams.toString()
}

export const getSearchParameter = (key: string): SearchParameterValue | undefined => {
  const urlSearchParams = readUrlSearchParameters()
  const { searchParams } = parseValidSearchParams(urlSearchParams)
  return searchParams[key]
}

export const setSearchParameter = (key: string, value: string): void | string[] => {
  const urlSearchParams = readUrlSearchParameters()
  urlSearchParams.set(key, value)
  const { validationMessagesMap } = parseValidSearchParams(urlSearchParams)
  if (validationMessagesMap.has(key)) {
    return validationMessagesMap.get(key)
  }

  writeUrlSearchParameters(urlSearchParams)
}

export const deleteSearchParameter = (key: string): void => {
  const urlSearchParams = readUrlSearchParameters()
  urlSearchParams.delete(key)
  writeUrlSearchParameters(urlSearchParams)
}

export const getValidationMessagesMap = (): Map<string, string[]> => {
  const urlSearchParams = readUrlSearchParameters()
  return parseValidSearchParams(urlSearchParams).validationMessagesMap
}
