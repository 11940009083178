import { WikimapiaBoxResultItem } from '../types'

export type BboxResultsAction =
  | { type: 'filter'; filter: string }
  | { type: 'update'; value: WikimapiaBoxResultItem[]; filter: string }
  | { type: 'delete' }

export type BboxResultsState = {
  all: WikimapiaBoxResultItem[]
  filtered: WikimapiaBoxResultItem[]
}

export const defaultBboxResultsState: BboxResultsState = {
  all: [],
  filtered: [],
}

export function bboxResultsReducer(state: BboxResultsState, action: BboxResultsAction) {
  switch (action.type) {
    case 'filter': {
      return {
        all: state.all,
        filtered: state.all.filter((item) => item.title.includes(action.filter)),
      }
    }
    case 'update': {
      return {
        all: action.value,
        filtered: action.value.filter((item) => item.title.includes(action.filter)),
      }
    }
    case 'delete': {
      return { all: [], filtered: [] }
    }
    default: {
      throw new Error('Unknown action type')
    }
  }
}
