import { DivIcon, geoJson, icon as LIcon, Map, Marker } from 'leaflet'
import { ALLOWED_PROVIDERS, CreateMapOptions, Marker as M } from '../../MapsWrapper/types'
import { MAP_GOOGLE, MAP_OSM, MAP_WIKI, MAP_YANDEX } from '../constants'
import GoogleProvider from './google'
import OpenStreetMapProvider from './openstreetmap'
import WikimapiaProvider from './wikimapia'
import YandexProvider from './yandex'
import icon from 'leaflet/dist/images/marker-icon.png'
import iconShadow from 'leaflet/dist/images/marker-shadow.png'
import Utils from '../../common/Utils'

const DefaultIcon = LIcon({
  iconUrl: icon,
  shadowUrl: iconShadow,
})

Marker.prototype.options.icon = DefaultIcon
export interface ProviderInterface {
  create(options: CreateMapOptions): Promise<Map>
}

const DefaultMapOptions = {
  zoomControl: true,
  gestureHandling: true,
  doubleClickZoom: 'center',
  scrollWheelZoom: 'center',
  centerMapIcon: true,
  maxZoom: 19,
}

const DefaultStyle = {
  fillColor: '#3cb2cf',
  color: '#3cb2cf',
  weight: 4,
  fillOpacity: 0.3,
}

const HoverStyle = {
  fillColor: '#fbb13a',
  color: '#fbb13a',
  weight: 2,
  fillOpacity: 0.3,
}
export class MapFactory {
  public static async create(options: CreateMapOptions): Promise<Map> {
    return this.getProvider(options.provider)
      .create(Object.assign({}, DefaultMapOptions, options))
      .then((map) => {
        if (options.centerMapIcon) MapFactory.centerScreenMarker(map)

        if (options.markers && options.markers.length > 0) {
          options.markers.forEach((m) => MapFactory.addNewMarkerToMap(m, map))
        }
        return map
      })
  }

  public static addNewMarkerToMap(marker: M, map: Map): void {
    new Marker(marker.point, { title: marker.title }).addTo(map)
  }

  public static addFeatureToMap(map: Map, feature: any): void {
    const item = geoJson(feature, {
      style: DefaultStyle,
    }).addTo(map)

    item.on('mouseover', function () {
      item.bindTooltip(feature.properties.name).openTooltip()
      item.setStyle(HoverStyle)
    })
    item.on('mouseout', function () {
      item.unbindTooltip()
      item.setStyle(DefaultStyle)
    })
    item.on('click', function () {
      const div = document.createElement('div')
      div.className = 'flex flex-col w-auto h-auto items-start'
      Object.keys(feature.properties).forEach((k) => {
        if (k === 'url') return

        div.innerHTML += `<div class="flex justify-start items-center w-full h-auto">
            <div class="flex justify-start w-24 font-medium">${k}</div>
            <div class="flex justify-start w-40 font-light italian">${feature.properties[k] || '-'}</div>
          </div>`
      })

      const btnDiv = document.createElement('div')
      btnDiv.className = 'flex w-full h-14 items-center justify-center'
      const button = document.createElement('button')

      button.className =
        'bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center'
      button.innerHTML =
        '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" data-slot="icon" class="w-6 h-6">\n' +
        '  <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75" />\n' +
        '</svg>'
      button.innerHTML += '&nbsp;Copy'

      button.onclick = function () {
        Utils.copyToClipboard(feature)
      }
      btnDiv.appendChild(button)
      div.appendChild(btnDiv)

      item.bindPopup(div).openPopup()
    })
  }

  private static getProvider(provider: ALLOWED_PROVIDERS): ProviderInterface {
    switch (provider) {
      case MAP_OSM:
        return new OpenStreetMapProvider()
      case MAP_YANDEX:
        return new YandexProvider()
      case MAP_WIKI:
        return new WikimapiaProvider()
      case MAP_GOOGLE:
      default:
        return new GoogleProvider()
    }
  }

  private static centerScreenMarker(map: Map): void {
    const marker = new Marker(map.getCenter(), {
      icon: new DivIcon({
        html:
          '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-full h-full">\n' +
          '  <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />\n' +
          '</svg>',
        className: 'bg-transparent w-14 h-14',
      }),
    }).addTo(map)
    map.on('move', () => marker.setLatLng(map.getCenter()))
  }
}
