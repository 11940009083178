import React, { createContext, MouseEventHandler } from 'react'

export interface AccessUnsafeResourceNativeEvent extends MouseEvent {
  isChecked: boolean
}

export interface AccessUnsafeResourceEvent<T> extends React.MouseEvent<T> {
  nativeEvent: AccessUnsafeResourceNativeEvent
}

export type PublicIPContextType = {
  checkPublicIP<T>(event: AccessUnsafeResourceEvent<T>, cb?: MouseEventHandler<T>): Promise<void>
}

export const PublicIPContext = createContext({} as PublicIPContextType)
